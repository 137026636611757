import axios from 'axios';
import router from '@/router'

const serve = axios.create({
  timeout: 15000,
  baseURL: process.env.NODE_ENV === "development" ? '/agency-api':'/'
})
//awardToolAuth/getCode
// 请求拦截器
serve.interceptors.request.use(
  config => {
    return config
  },
  error => Promise.error(error)
)

// 响应拦截器
serve.interceptors.response.use(
  res => {
    // token过期
    if(res.data.code == '401'){
      // localStorage.clear()
      localStorage.removeItem('token')
      localStorage.removeItem('useInfo')
      router.replace({
        path: '/login',
      })
      return
    }
    return res.status == 200 ? Promise.resolve(res) : Promise.reject(res)
  },
  error => {
    console.log('error ==>', error)
    return
  }
)
/**
 *axios
 *@param：{string} method 请求类型,必填
 *@param：{string} url 请求地址,必填
 *@param：{string} params 请求参数,非必填
 *@param：{string} type 请求模式，针对get，params/data
 *@param：{string} variation 请求头,非必填
 **/
export const request = (
  method,
  url,
  params = {},
  type = "data",
  isToken = true
) => {
  let headers = { "Content-Type": "application/json" };
  if (isToken) {
    headers["Authorization"] = 'Bearer' + ' ' + localStorage.getItem("token");
  }
  if (method == "get" || type == "params") {
    return serve({
      method: method,
      url: url + (url.includes('?') ? '&' : '?') + 'timestamp='+new Date().getTime(),
      headers: headers,
      params: params,
    }).then((res) => res.data);
  } else {
    return serve({
      method: method,
      url: url + (url.includes('?') ? '&' : '?') + 'timestamp='+new Date().getTime(),
      headers: headers,
      data: params,
    }).then((res) => res.data);
  }
};
