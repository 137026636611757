import Vue from "vue";
import VueRouter from "vue-router";
import mobileIndex from "@/views/mobile/index";
import webIndex from "@/views/webPage/index";

// PC端的子路由页面
import home from "@/views/webPage/home";
import webCase from "@/views/webPage/case";
import equityGoods from "@/views/webPage/equityGoods.vue";
import webOpenPlatform from "@/views/webPage/webOpenPlatform.vue";
import about from "@/views/webPage/about.vue";

// 移动端的子路由页面
import mobileHome from "@/views/mobile/home";
import industryCases from "@/views/mobile/industryCases";
import entitlementGoods from "@/views/mobile/entitlementGoods";
import openPlatform from "@/views/mobile/openPlatform";
import aboutUs from "@/views/mobile/aboutUs";

Vue.use(VueRouter);

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes: [
    {
      path: "/mobileIndex",
      component: mobileIndex,
      children: [
        {
          path: "",
          component: mobileHome,
        },
        {
          path: "/mobileHome",
          component: mobileHome,
        },
        {
          path: "/industryCases",
          component: industryCases,
          meta: {
            title: "行业案例",
          },
        },
        {
          path: "/entitlementGoods",
          component: entitlementGoods,
          meta: {
            title: "权益商品",
          },
        },
        {
          path: "/openPlatform",
          component: openPlatform,
          meta: {
            title: "开发平台",
          },
        },
        {
          path: "/aboutUs",
          component: aboutUs,
          meta: {
            title: "关于我们",
          },
        },
      ],
    },
    {
      path: "/webIndex",
      component: webIndex,
      children: [
        {
          path: "",
          component: home,
        },
        {
          path: "/home",
          component: home,
        },
        {
          path: "/webCase",
          component: webCase,
          meta: {
            title: "行业案例",
            // keepAlive: true // 是否使用 keep-alive
          },
        },
        {
          path: "/equityGoods",
          component: equityGoods,
          meta: {
            title: "权益商品",
            // keepAlive: true // 是否使用 keep-alive
          },
        },
        {
          path: "/webOpenPlatform",
          component: webOpenPlatform,
          meta: {
            title: "开放平台",
            // keepAlive: true // 是否使用 keep-alive
          },
        },
        {
          path: "/about",
          component: about,
          meta: {
            title: "关于我们",
            // keepAlive: true // 是否使用 keep-alive
          },
        },
      ],
    },
  ],
});

//2.使用router.beforeEach对路由进行遍历，设置title
router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title;
  } else {
    document.title = "深圳赛迪文信息科技有限公司";
  }
  next();
});

export default router;
