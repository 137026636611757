<template>
  <div class="container-title">
    <div class="title-box">
      <div class="title">{{title}}</div>
    </div>
    <div class="tip">{{tip}} <span class="call" v-if="call == 1" @click="callPhone('400-658-0368')">拨打</span> </div>
  </div>
</template>

<script>
  export default {
    name: "mobileTitle",
    props: {
      title: {
        type: String,
        default: ''
      },
      tip: {
        type: String,
        default: ''
      },
      call: {
        type: String,
        default: '0'
      },
    },
    methods: {
      // 调用拨号功能
      callPhone (phoneNumber) {
        window.location.href = 'tel://' + phoneNumber
      }
    } 
  }
</script>

<style lang="less" scoped>
.container-title{
  background-image: url('@{link}/h5img/home17.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 2.44rem;
  .title-box{
    border: 1px solid #FDA025;
    border-radius: 0.1rem;
    display: inline-block;
    margin-top: 0.90rem;
    .title{
      color: #fff;
      font-size: 0.32rem;
      height: 0.68rem;
      line-height: 0.68rem;
      border-radius: 0.1rem;
      background: #FDA025;
      padding: 0 0.4rem;
      box-sizing: border-box;
      transform: translate(-0.08rem, -0.08rem);
    }
  }
  .tip{
    color: #fff;
    font-size: 0.24rem;
    margin-top: 0.12rem;
    letter-spacing: 1px;
    .call{
      color: #FDA025;
      background: #fff;
      padding: 0.05rem 0.14rem;
      border-radius: 0.1rem;
      margin-left: 0.1rem;
    }
  }
}
</style>