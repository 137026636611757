<template>
  <div class="industry-cases">
    <div class="head-box">
      <img class="case-top" :src="`${$link}/h5img/case01.png`" alt="" srcset="">
      <div class="carousel">
        <div class="flex-box">
          <div class="bank-box" :style="{color: `${index == selected ? '#0F62FE' : '#333333'}`}" @click="choose(index)" v-for="(item, index) in menuList" :key="index">
            <div class="name">{{item}}</div>
            <div class="line" :style="{background: `${index == selected ? '#0F62FE' : 'transparent' }`}"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="products">
      <template v-if="list.length > 0">
        <div class="product-box" v-for="item in list" :key="item.id">
          <img class="product-img" :src="item.caseImg" alt="">
          <div class="title">{{item.caseName}}</div>
          <template>
            <div class="name">合作客户</div>
            <div class="info">{{item.cooperativeClient}}</div>
          </template>
          <template>
            <div class="name">活动类型</div>
            <div class="info">{{menuList[item.activityType]}}</div>
          </template>
          <template>
            <div class="name">关键词</div>
            <div class="info">{{item.keyword}}</div>
          </template>
          <template>
            <div class="name">活动内容</div>
            <div class="info">{{item.activityContent}}</div>
          </template>
        </div>
      </template>
      <template v-else>
        <el-empty :image-size="150" description="暂无数据"></el-empty>
      </template>
    </div>
    <mobile-toast :msg="tmsg" :state="state" />
    <mobile-foot />
  </div>
</template>

<script>
import mobileFoot from '@/components/mobileFoot.vue'
import MobileToast from '@/components/mobileToast.vue'
  export default {
  components: { mobileFoot, MobileToast },
    name: "industryCases",
    data() {
      return {
        selected: 0,
        menuList: ['全部', '信用卡开卡', '活卡、绑卡', '手机银行支付'],
        list: [],
        tmsg: '',
        state: ''
      }
    },
    created () {
      this.getCaseList()
    },
    methods: {
      choose(e) {
        this.selected = e
        this.getCaseList()
      },
      getCaseList() {
        this.tmsg = "加载中..."
        this.state = 'loading'
        let obj = {
          activityType: this.selected
        }
        this.$api.listCaseCooperation(obj)
        .then(res => {
          if(res.code == 200) {
            this.list = res.data
          }
          this.state = ''
        })
      }
    },
  }
</script>

<style lang="less" scoped>
.industry-cases{
  img{
    display: block;
  }
  .head-box{
    width: 100%;
    height: 4.4rem;
    .case-top{
      width: 100%;
      
    }
    .carousel{
      background: #fff;
      box-shadow: 0 0 0.20rem 0 #0000001a;
      border-radius: 0.20rem;
      overflow-x: scroll;
      margin: 0 0.36rem;
      padding: 0 0.2rem;
      transform: translateY(-0.52rem);
      
      .flex-box{
        color: #333;
        font-size: 0.28rem;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        height: 1.34rem;
        .bank-box{
          white-space: nowrap;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 0.2rem;
          .name{
            line-height: 0.4rem;
          }
          .line{
            width: 0.56rem;
            height: 0.04rem;
            margin-top: 0.1rem;
            border-radius: 0.02rem;
          }
        }
      }
    }
  }
  .products{
    padding: 1rem 0.36rem 0;
    .product-box{
      background: #fff;
      box-shadow: 0 0 0.20rem 0 #0000001a;
      border-radius: 0.20rem;
      padding: 0.6rem 0.3rem 0;
      text-align: left;
      margin-bottom: 0.2rem;
      .product-img{
        width: 4rem;
        height: 4.62rem;
        margin: 0 auto 0.6rem;
      }
      .title{
        color: #333333;
        font-size: 0.36rem;
        padding-bottom: 0.5rem;
      }
      .name{
        color: #333333;
        font-size: 0.32rem;
        margin-bottom: 0.2rem;
      }
      .info{
        color: #777777;
        font-size: 0.28rem;
        padding-bottom: 0.4rem;
        line-height: 0.48rem;
      }
    }
  }

}
</style>