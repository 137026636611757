<template>
  <div class="container" id="header">
    <div class="banner">
      <div class="headImg">
        <img
          :src="`${$link}/webimg/logo_head.png`"
          alt=""
        />
      </div>
      <div class="nav_box">
        <div class="nav_item">
          <a @click="chooseItem(1)" :class="navIndex == 1 ? 'nav_a' : ''"
            >首页</a
          >
          <span :class="navIndex == 1 ? 'nav_active' : ''"></span>
        </div>
        <div class="nav_item">
          <a @click="chooseItem(2)" :class="navIndex == 2 ? 'nav_a' : ''"
            >行业案例</a
          >
          <span :class="navIndex == 2 ? 'nav_active' : ''"></span>
        </div>
        <div class="nav_item">
          <a @click="chooseItem(3)" :class="navIndex == 3 ? 'nav_a' : ''"
            >权益商品</a
          >
          <span :class="navIndex == 3 ? 'nav_active' : ''"></span>
        </div>
        <div class="nav_item">
          <a @click="chooseItem(4)" :class="navIndex == 4 ? 'nav_a' : ''"
            >开放平台</a
          >
          <span :class="navIndex == 4 ? 'nav_active' : ''"></span>
        </div>
        <div class="nav_item">
          <a @click="chooseItem(5)" :class="navIndex == 5 ? 'nav_a' : ''"
            >关于我们</a
          >
          <span :class="navIndex == 5 ? 'nav_active' : ''"></span>
        </div>
        <div class="btn" @click="chooseItem(6)">
          <a :class="navIndex == 6 ? 'nav_a' : ''" href="#footForm">联系我们</a>
          <span :class="navIndex == 6 ? 'nav_active' : ''"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'webHead',
  data () {
    return {
      navIndex: 1
    }
  },
  watch: {
    $route: {
      handler () {
        this.init()
      },
      immediate: true
    }
  },
  // created () {
  //   this.init()
  // },
  methods: {
    init () {
      let path = localStorage.getItem('current_page')
      switch (path) {
        case '/webCase':
          this.navIndex = 2
          break
        case '/equityGoods':
          this.navIndex = 3
          break
        case '/webOpenPlatform':
          this.navIndex = 4
          break
        case '/about':
          this.navIndex = 5
          break
        default:
          this.navIndex = 1
      }
    },
    /* 点击导航 */
    chooseItem (e) {
      // console.log(e)
      this.navIndex = e
      switch (e) {
        case 1:
          localStorage.setItem('current_page', '/home')
          this.$router.push('./home')
          break
        case 2:
          localStorage.setItem('current_page', '/webCase')
          this.$router.push('./webCase')
          break
        case 3:
          localStorage.setItem('current_page', '/equityGoods')
          this.$router.push('./equityGoods')
          break
        case 4:
          localStorage.setItem('current_page', '/webOpenPlatform')
          this.$router.push('./webOpenPlatform')
          break
        case 5:
          localStorage.setItem('current_page', '/about')
          this.$router.push('./about')
          break
        default:
          this.navIndex = 1
          localStorage.setItem('current_page', '/home')
          this.$router.push({
            path: '/home',
            query: { contact: 1 }
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.container {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  .banner {
    min-width: 1200px;
    height: 80px;
    font-size: 18px;
    // background-color: skyblue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headImg {
      // width: 284px;
      height: 45px;
      margin-right: 223px;
      img {
        // width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .nav_box {
      width: 786px;
      height: 80px;
      // background-color: #fff;
      box-sizing: border-box;
      display: flex;
      // background-color: skyblue;
      align-items: center;
      font-size: 18px;
      color: #555555;
      .nav_item {
        width: 157px;
        height: 25px;
        position: relative;
        &:hover {
          color: #00a0fb;
          cursor: pointer;
        }
        .nav_active {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 36px;
          height: 3px;
          background: #00a0fb;
          border-radius: 2.5px;
          left: 50%;
          transform: translateX(-50%);
          top: 40px;
        }
        .nav_a {
          color: #00a0fb;
        }
      }
      .btn {
        color: #ffffff;
        background: #00a0fb;
        width: 136px;
        height: 50px;
        line-height: 50px;
        border-radius: 10px;
        font-size: 18px;
        cursor: pointer;
        a {
          color: #ffffff;
        }
      }
      li:hover {
        color: #00a0fb;
        cursor: pointer;
      }
      a {
        text-decoration: none;
        height: 25px;
        font-weight: 500;
        font-size: 18px;
        // background-color: yellow;
      }
    }
  }
}
</style>
