import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import api from "./api/index.js";
import {
  Button,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Form,
  FormItem,
  Input,
  Row,
  Col,
  Empty,
  Popover,
  Message,
  Notification,
} from "element-ui";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import toTop from "@/components/toTop.vue";

// 全局变量
// Vue.prototype.$link = 'https://yuedong-official-web.oss-cn-shenzhen.aliyuncs.com';
Vue.prototype.$link = 'https://file-official.saidiwen.cn'
/**
 * 放置重复提交自定义指令
 * 使用方式 <div class="btn" v-preventReClick="receive"></div>
 */
import preventReClick from "@/common/customInstructions.js";

Vue.use(preventReClick);
Vue.use(Button);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Row);
Vue.use(Col);
Vue.use(Popover);

Vue.use(VueAwesomeSwiper);
Vue.config.productionTip = false;
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;
Vue.prototype.$api = api;
Vue.use(Empty);
Vue.component("toTop", toTop);

new Vue({
  render: (h) => h(App),
  router,
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      //移动端使用
      setRem();
      window.addEventListener("resize", setRem); //浏览器窗口大小改变时调用rem换算方法
    }
    // else {
    //   //pc端使用
    //   setRemPC();
    //   window.addEventListener("resize", setRemPC); //浏览器窗口大小改变时调用rem换算方法
    // }
  },
}).$mount("#app");

// 手机端
function setRem() {
  var whdef = 100 / 750; // 表示750的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
  var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName("html")[0].style.fontSize = rem + "px";
}
//pc端
// function setRemPC() {
//   var whdef = 100 / 1920; // 表示1920的设计图,使用100PX的默认值
//   var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
//   var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
//   document.getElementsByTagName("html")[0].style.fontSize = rem + "px";
// }
