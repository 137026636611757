<template>
  <div class="mobileCollCases-box">
    <div class="mobileCollCases">
      <div :class="['brief_introduction', `bg${selected.id + 1}`]">
        <div class="introduction">
          {{ selected.message }}
        </div>
        <div class="brief_foot">
          <img :class="['bank-logo']" :src="selected.logo" alt="" />
          <div class="case-btn" @click="formPop">银行解决方案 ></div>
        </div>
      </div>
    </div>
    <div class="carousel">
      <div class="flex-box">
        <div
          class="bank-box"
          :style="{ opacity: `${index == selected.id ? 1 : 0.6}` }"
          @click="choose(item)"
          v-for="(item, index) in bankList"
          :key="index"
        >
          <img class="opt-img" :src="item.logo" alt="" />
        </div>
      </div>
    </div>
    <div class="more" @click="formPop">查看更多合作案例 ></div>
  </div>
</template>

<script>
export default {
  name: 'mobileCollCases',
  data () {
    return {
      selected: {},
      bankList: [
        {
          id: 0,
          logo: this.$link + '/h5img/bank_logo01.png',
          message:
            '通过赛迪文定制活动、积分运营、微信立减金发放，为中国银行提升在客户活跃、开卡等数据指标'
        },
        {
          id: 1,
          logo: this.$link + '/h5img/bank_logo02.png',
          message:
            '与交通银行进行微信绑卡相关合作，为银行提供微信绑卡优惠等相关活动，提升用户活跃度'
        },
        {
          id: 2,
          logo: this.$link + '/h5img/bank_logo03.png',
          message: '通过赛迪文积分商城、积分营销，为农业银行提升客户资产规模'
        },
        {
          id: 3,
          logo: this.$link + '/h5img/bank_logo04.png',
          message:
            '通过赛迪文服务商在第三方支付宝平台支付满减，提高建设银行用户活跃度、资产动账'
        },
        {
          id: 4,
          logo: this.$link + '/h5img/bank_logo05.png',
          message:
            '通过赛迪文定制银行立减活动，促进开卡绑卡业务，提升了银行客户活跃度等数据'
        },
        {
          id: 5,
          logo: this.$link + '/h5img/bank_logo06.png',
          message:
            '通过赛迪文权益商品供应，实现秒杀活动奖励发放，为招商银行提升用户活跃度'
        },
        {
          id: 6,
          logo: this.$link + '/h5img/bank_logo07.png',
          message:
            '与兴业银行话费AP对接，实现话费平台对接，解决了兴业银行话费充值等相关问题'
        },
        {
          id: 7,
          logo: this.$link + '/h5img/bank_logo08.png',
          message: '与北部湾银行进行长期，提供各种合作权益类及活动技术支撑'
        },
        {
          id: 8,
          logo: this.$link + '/h5img/bank_logo09.png',
          message:
            '与桂林银行进行长期合作，支撑银行各项活动内容，提供各种权益商品'
        }
      ]
    }
  },
  created () {
    this.selected = this.bankList[0]
  },
  methods: {
    choose (e) {
      this.selected = e
    },
    formPop () {
      this.$router.push({
        path: '/industryCases'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.mobileCollCases {
  margin-top: 0.44rem;
  background: url('@{link}/h5img/home20.png') no-repeat 0 bottom;
  background-size: 100% 1.42rem;
  .bg1 {
    background: url('@{link}/h5img/home21.png'),
      url('@{link}/h5img/bank_bg01.png');
  }
  .bg2 {
    background: url('@{link}/h5img/home21.png'),
      url('@{link}/h5img/bank_bg02.png');
  }
  .bg3 {
    background: url('@{link}/h5img/home21.png'),
      url('@{link}/h5img/bank_bg03.png');
  }
  .bg4 {
    background: url('@{link}/h5img/home21.png'),
      url('@{link}/h5img/bank_bg04.png');
  }
  .bg5 {
    background: url('@{link}/h5img/home21.png'),
      url('@{link}/h5img/bank_bg05.png');
  }
  .bg6 {
    background: url('@{link}/h5img/home21.png'),
      url('@{link}/h5img/bank_bg06.png');
  }
  .brief_introduction {
    height: 3.5rem;
    margin: 0 0.36rem;
    box-shadow: 0 0 0.3rem 0 #0000001a;
    border-radius: 0.2rem;
    background-repeat: no-repeat, no-repeat;
    background-color: #fff;
    background-size: 0.46rem 0.46rem, 1.52rem 1.62rem;
    background-position: 0.66rem 0.24rem, bottom left;
    transform: translateY(-0.44rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .introduction {
      color: #333333;
      font-size: 0.28rem;
      letter-spacing: 1px;
      line-height: 0.5rem;
      padding: 0.64rem 0.8rem 0.14rem 1.28rem;
      text-align: left;
    }
    .brief_foot {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.8rem 0 1rem;
      margin-bottom: 0.3rem;
      .bank-logo {
        display: block;
        width: 2rem;
        height: 1rem;
      }
      .case-btn {
        width: 1.98rem;
        height: 0.54rem;
        border: 1px solid #0f62fe;
        border-radius: 0.1rem;
        font-size: 0.24rem;
        color: #0f62fe;
        letter-spacing: 1px;
        line-height: 0.54rem;
      }
    }
  }
}
.carousel {
  width: 100%;
  overflow-x: scroll;
  margin-top: 0.3rem;
  .flex-box {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .bank-box {
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: 0.2rem;
      margin-left: 0.2rem;
      .opt-img {
        height: 100%;
        display: block;
      }
    }
  }
}
.more {
  font-size: 0.28rem;
  color: #ffffff;
  letter-spacing: 1px;
  margin: 0.3rem 0 0.8rem;
}
</style>
