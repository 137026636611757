<template>
  <div>
    <web-head />
    <keep-alive>
      <router-view v-if="$route.meta.keep"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keep"></router-view>
    <web-foot />
  </div>
</template>

<script>
import webHead from '@/components/webHead'
  import webFoot from '@/components/webFoot'
  export default {
    name: "webIndex",
    components: {
      webHead,
      webFoot
    },
    methods: {
      toLink(e) {
        this.$router.push({
          path: e
        })
      }
    }
  }
</script>

<style lang="less" scoped>

</style>