<template>
  <div class="openPlatform">
    <div class="head_img">
      <img
        :src="`${$link}/webimg/openPlatform_bgm.png`"
        alt=""
      />
    </div>
    <div class="interaction_Box">
      <p>悦动互动营销权益平台</p>
      <span>
        [工具+服务+营销]一步到位，一站式解决方案，让营销简单、精准、有效
      </span>
      <div></div>
    </div>
    <div class="step_box">
      <div class="step_item dashed">
        <img
          :src="`${$link}/webimg/flow_path1.png`"
          alt=""
        />
        <span>联系业务经理</span>
        <p>洽谈合作事宜，开通平台账号</p>
      </div>
      <div class="step_item">
        <img
          :src="`${$link}/webimg/flow_path2.png`"
          alt=""
        />
        <span>API接口接入</span>
        <p>对接接口进行调试，悦动权益提供 免费技术支持</p>
      </div>
      <div class="step_item">
        <img
          :src="`${$link}/webimg/flow_path3.png`"
          alt=""
        />
        <span>数字权益商品配置</span>
        <p>配置权益商品种类、单价等信息</p>
      </div>
      <div class="step_item">
        <img
          :src="`${$link}/webimg/flow_path4.png`"
          alt=""
        />
        <span>正式使用</span>
        <p>通过接口正式调用</p>
      </div>
    </div>
    <div class="scene_box">
      <div class="interaction_Box">
        <p>数字权益商品API接口应用场景</p>
        <span> 数字权益商品API接口可广泛应用于营销、福利、积分兑换等场景 </span>
        <div></div>
      </div>
      <div class="scene_box_bom">
        <div class="scene_box_bom_item">
          <img
            :src="`${$link}/webimg/scene_item1.png`"
            alt=""
          />
          <span>引流活动</span>
          <p>
            企业的拉新促活营销活动中，通过API接口实时
            调用数字商品，提升营销效果与用户体验
          </p>
        </div>
        <div class="scene_box_bom_item">
          <img
            :src="`${$link}/webimg/scene_item2.png`"
            alt=""
          />
          <span>积分商城</span>
          <p>
            企业的积分商城接入数字权益商品API，可增加商品品类、无需考虑库存与物流，极大节约运营成本
          </p>
        </div>
        <div class="scene_box_bom_item">
          <img
            :src="`${$link}/webimg/scene_item3.png`"
            alt=""
          />
          <span>企业福利</span>
          <p>
            企业、工会内部福利平台接入权益商品API，为员工提供更多商品选择，提升员工满意度
          </p>
        </div>
      </div>
    </div>
    <div class="major_function_box">
      <div class="interaction_Box">
        <p>悦动权益 商品API接口平台主要功能</p>
        <span> 数字权益商品API接口可广泛应用于营销、福利、积分兑换等场景 </span>
        <div></div>
      </div>
      <div class="major_function_box_bom">
        <div class="major_function_box_bom_item">
          <img
            :src="`${$link}/webimg/fun_item1.png`"
            alt=""
          />
          <span>数字权益商品管理</span>
          <p>
            可对接入的商品进行管理，可查询商品信息、价格信息、面值信息、阵存情况、商品属性等
          </p>
        </div>
        <div class="major_function_box_bom_item">
          <img
            :src="`${$link}/webimg/fun_item2.png`"
            alt=""
          />
          <span>数字权益商品订单管理</span>
          <p>可自助查看订单详情，管理订单，查看订单明细。支持API接口配置查询</p>
        </div>
        <div class="major_function_box_bom_item">
          <img
            :src="`${$link}/webimg/fun_item3.png`"
            alt=""
          />
          <span>数字权益商品系统配置</span>
          <p>
            通过API可以对商品系统进行配置，登录验证、余额提醒、名单管理，权限管理等
          </p>
        </div>
      </div>
    </div>
    <div class="api_box">
      <div class="interaction_Box">
        <p>数字权益商品API接口优势</p>
        <span>
          多年的功能迭代与技术创新，众多客户服务经验的沉淀，造就了可靠、安全、高效的数字商品API接口平台
        </span>
        <div></div>
      </div>
      <div class="api_box_bom">
        <div class="api_box_bom_item">
          <img
            :src="`${$link}/webimg/api_item1.png`"
            alt=""
          />
          <span>充值秒到账</span>
          <p>直连品牌商，重组商品库存，商品充值秒到账</p>
        </div>
        <div class="api_box_bom_item">
          <img
            :src="`${$link}/webimg/api_item2.png`"
            alt=""
          />
          <span>丰富的数字权益商品</span>
          <p>覆盖全品类的数字权益商品品类，满足用户 各场景需求</p>
        </div>
        <div class="api_box_bom_item">
          <img
            :src="`${$link}/webimg/api_item3.png`"
            alt=""
          />
          <span>自助对账</span>
          <p>在线生成订单报告，可在线自助对账</p>
        </div>
        <div class="api_box_bom_item">
          <img
            :src="`${$link}/webimg/api_item4.png`"
            alt=""
          />
          <span>失败自动补单</span>
          <p>实时侦测充值状态，失败自动补单，提升成功率</p>
        </div>
        <div class="api_box_bom_item">
          <img
            :src="`${$link}/webimg/api_item5.png`"
            alt=""
          />
          <span>多个资源自动路由</span>
          <p>动态监控各资源线路健康状况，如遇到 异常自动路由</p>
        </div>
        <div class="api_box_bom_item">
          <img
            :src="`${$link}/webimg/api_item6.png`"
            alt=""
          />
          <span>专用短信通道</span>
          <p>专用短信通道发送券码，安全、快速、稳定</p>
        </div>
      </div>
    </div>
    <!-- 回到顶部 -->
    <toTop />
  </div>
</template>

<script>
export default {
  name: 'openPlatform'
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 16px;
}
.openPlatform {
  .head_img {
    width: 100%;
    height: 600px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .interaction_Box {
    margin: 0;
    height: 260px;
    box-sizing: border-box;
    padding-top: 100px;
    position: relative;
    p {
      // height: 42px;
      font-weight: 700;
      font-size: 30px;
      color: #333333;
    }
    span {
      margin-top: 15px;
      display: inline-block;
      color: #00a0fb;
    }
    div {
      display: inline-block;
      width: 86px;
      height: 4px;
      position: absolute;
      top: 197px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #00a0fb;
      border-radius: 2px;
    }
  }
  .step_box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    .step_item {
      width: 222px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      img,
      span,
      p {
        display: block;
      }
      img {
        width: 120px;
        height: 120px;
      }
      span {
        margin: 30px 0 10px 0;
        height: 25px;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
      }
      p {
        width: 222px;
        height: 48px;
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        line-height: 24px;
      }
      &:nth-child(1)::after,
      &:nth-child(2)::after,
      &:nth-child(3)::after {
        content: '';
        display: inline-block;
        width: 195px;
        height: 1px;
        background-image: url('@{link}/webimg/dashed.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 60px;
        left: 175px;
      }
    }
  }
  .scene_box {
    width: 100%;
    background: #f8fafc;

    .scene_box_bom {
      width: 1200px;
      margin: 0 auto;
      //   background-color: skyblue;
      //   height: 400px;
      display: flex;
      justify-content: space-between;
      .scene_box_bom_item {
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
        img,
        span,
        p {
          display: block;
        }
        img {
          height: 160px;
          width: 160px;
        }
        span {
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          margin: 30px 0 10px 0;
        }
        p {
          width: 350px;
          height: 48px;

          font-weight: 500;
          font-size: 14px;
          color: #777777;

          text-align: center;
          line-height: 24px;
        }
      }
    }
  }
  .major_function_box {
    .major_function_box_bom {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .major_function_box_bom_item {
        width: 386px;
        height: 432px;
        background: #ffffff;
        border: 1px solid #e1e8f0;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
        position: relative;
        img,
        span,
        p {
          display: block;
        }
        img {
          width: 256px;
          height: 185px;
          margin-top: 31px;
        }
        span {
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          margin: 54px 0 15px 0;
        }
        p {
          width: 306px;
          height: 72px;
          font-weight: 500;
          font-size: 14px;
          color: #555555;
          letter-spacing: 0.78px;
          text-align: center;
          line-height: 24px;
        }
        &::after {
          content: '';
          display: block;
          width: 60px;
          height: 4px;
          background: #00a0fb;
          border-radius: 2px;
          position: absolute;
          top: 236px;
          left: 163px;
        }
      }
    }
  }
  .api_box {
    width: 100%;
    background: #f8fafc;
    .api_box_bom {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 40px;
      .api_box_bom_item {
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img,
        span,
        p {
          display: block;
        }
        img {
          width: 120px;
          height: 120px;
        }
        span {
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          margin: 30px 0 10px 0;
        }
        p {
          font-weight: 500;
          font-size: 14px;
          color: #777777;
          margin-bottom: 84px;
        }
      }
    }
  }
}
</style>
