<template>
  <div class="case">
    <div class="case_hed_bgm">
      <img
        :src="`${$link}/webimg/case_had_img.png`"
        alt=""
      />
    </div>
    <div class="case_meun">
      <div class="case_meun_lef">
        <span>活动</span>
      </div>
      <div class="case_meun_rig">
        <span
          v-for="(item, index) in menuList"
          :key="index"
          @click="meunActive(index)"
          :class="active == index ? 'meun_active' : ''"
          >{{ item }}</span
        >
      </div>
    </div>
    <div class="case_box">
      <div class="case_item" v-for="item in caseLisst" :key="item.id">
        <div class="case_item_left">
          <img :src="item.caseImg" alt="" />
        </div>
        <div class="case_item_right">
          <h3>{{ item.caseName }}</h3>
          <div class="case_item_right_item">
            <span>合作客户</span>
            <p>{{ item.cooperativeClient }}</p>
          </div>
          <div class="case_item_right_item">
            <span>活动类型</span>
            <p>{{ menuList[item.activityType] }}</p>
          </div>
          <div class="case_item_right_item">
            <span>关键词</span>
            <p>{{ item.keyword }}</p>
          </div>
          <div class="case_item_right_item">
            <span>活动内容</span>
            <p>
              {{ item.activityContent }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 回到顶部 -->
    <toTop />
  </div>
</template>

<script>
export default {
  name: 'webCase',
  data () {
    return {
      active: '0',
      menuList: ['全部', '信用卡开卡', '活卡、绑卡', '手机银行支付'],
      caseLisst: []
    }
  },
  created () {
    this.getCaseList()
  },
  methods: {
    getCaseList () {
      let obj = {
        activityType: this.active
      }
      this.$api.listCaseCooperation(obj).then(res => {
        if (res.code == 200) {
          this.caseLisst = res.data
        }
      })
    },
    meunActive (e) {
      this.active = e
      this.getCaseList()
    }
  }
}
</script>

<style lang="less" scoped>
.case {
  font-size: 18px;
  .case_hed_bgm {
    width: 100%;
    max-height: 800px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .case_meun {
    width: 1200px;
    height: 170px;
    background: #ffffff;
    box-shadow: 0 0 20px 0 #00000026;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: -60px;
    margin-bottom: 80px;
    display: flex;
    position: relative;
    .case_meun_lef {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 150px;
      // background: #ccc;
      height: 100%;
      text-align: center;
      line-height: 170px;
      span {
        margin-left: 10px;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
        letter-spacing: 0;
      }
    }
    .case_meun_rig {
      width: 1050px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      border-radius: 0 10px 10px 0;
      text-align: left;
      span {
        display: inline-block;
        margin: 60px 15px;
        line-height: 50px;
        padding: 5px 20px;
        height: 50px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        cursor: default;
      }
      .meun_active {
        // width: 140px;
        // padding: 5px 30px;
        // margin-left: 50px;
        background: #00a0fb;
        color: #ffffff;
        border-radius: 10px;
      }
    }
  }
  .case_box {
    width: 1200px;
    margin: 0 auto;

    .case_item {
      height: 518px;
      margin-bottom: 100px;
      display: flex;
      text-align: left;
      .case_item_left {
        width: 486px;
        height: 100%;
        img {
          display: block;
          width: 486px;
          height: 100%;
        }
      }
      .case_item_right {
        width: 747px;
        margin-left: 40px;
        // padding-top: 15px;
        h3 {
          width: 349px;
          height: 33px;
          margin: 0;
          font-weight: 500;
          font-size: 24px;
          color: #333333;
          margin-bottom: 40px;
        }
        .case_item_right_item {
          // height: 55px;
          margin-bottom: 30px;
          span {
            width: 72px;
            height: 25px;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
          }
          p {
            width: 707px;
            letter-spacing: 0.5px;
            line-height: 24px;
            font-weight: 500;
            font-size: 14px;
            color: #777777;
          }
        }
      }
    }
  }
}
</style>
