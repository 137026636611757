export default {
  install(Vue) {
  // 防止重复点击 
    Vue.directive("preventReClick", {
      inserted(el, binding) {
        let timer = null;
        el.addEventListener("click", () => {
          const firstClick = !timer;
          if (firstClick) {
            binding.value();
          }
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            timer = null;
          }, 2000);
        });
      },
    });
  },
};
