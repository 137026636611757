<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keep"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keep"></router-view>
  </div>
</template>

<script>
import IsPC from '@/common/deviceIdentification'
export default {
  name: 'App',
  data () {
    return {
      beforeUnload_time: '',
      gap_time: ''
    }
  },
  created () {
    let current_page = localStorage.getItem('current_page')
    // console.log('current_page ==>', current_page)
    /*如果不是pc设备访问   跳转到移动端*/
    if (!IsPC()) {
      // 移动端页面路径
      this.$router.replace(
        {
          path: current_page ?? '/mobileHome'
        },
        () => {}
      )
    } else {
      this.$router.replace(
        {
          path: current_page ?? '/home'
        },
        () => {}
      )
    }
  },
  mounted () {
    let beginTime = 0 //开始时间
    let differTime = 0 //时间差
    window.onunload = function () {
      differTime = new Date().getTime() - beginTime
      if (differTime <= 5) {
        localStorage.removeItem('current_page')
        console.log('这是关闭')
      } else {
        console.log('这是刷新')
      }
    }
    window.onbeforeunload = function () {
      beginTime = new Date().getTime()
    }
  }
}
</script>

<style>

html,
body,
#app {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
