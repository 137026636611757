<template>
  <div class="container">
    <div class="head" :style="{background: `${ bg == 1 ? 'transparent' : '#0F62FE'} `, 'box-shadow': `${ scrollTop <= 50 ? 'none' : '0 4px 16px 0 #00000026'}`}">
      <div class="img-logo">
        <img :src="`${$link}/h5img/logo.png`">
      </div>
      <div class="img-icon" @click="showMenu = !showMenu">
        <img :src="`${$link}/h5img/icon.png`">
      </div>
      <transition name="el-zoom-in-top">
        <div v-show="showMenu" class="menu">
          <div class="menu-opt" @click="toLink('/mobileHome')">
            <img class="menu-icon" :src="`${$link}/h5img/icon01.png`" alt="">
            <div class="menu-title">首页&emsp;&emsp;</div>
          </div>
          <div class="menu-opt" @click="toLink('/industryCases')">
            <img class="menu-icon" :src="`${$link}/h5img/icon02.png`" alt="">
            <div class="menu-title">行业案例</div>
          </div>
          <div class="menu-opt" @click="toLink('/entitlementGoods')">
            <img class="menu-icon" :src="`${$link}/h5img/icon03.png`" alt="">
            <div class="menu-title">权益商品</div>
          </div>
          <div class="menu-opt" @click="toLink('/openPlatform')">
            <img class="menu-icon" :src="`${$link}/h5img/icon04.png`" alt="">
            <div class="menu-title">开放平台</div>
          </div>
          <div class="menu-opt" @click="toLink('aboutUs')">
            <img class="menu-icon" :src="`${$link}/h5img/icon05.png`" alt="">
            <div class="menu-title">关于我们</div>
          </div>
        </div>
      </transition>
    </div>
    
    <div class="placeholder-box" v-if="$route.path != '/mobileHome' && bg != 1"></div>
  </div>
</template>

<script>
  export default {
    name: "mobileHead",
    props: {
      scrollTop: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        bg: 0,
        showMenu: false,
      }
    },
    watch: {
      $route: {
        handler() {
          // console.log(this.$route.path)
          if(this.$route.path == '/mobileHome') {
            this.bg = 1
          }else {
            this.bg = 0
          }
        },
        immediate: true
      },
      scrollTop: {
        handler(val) {
          this.showMenu = false
          if (val > 50) {
            this.bg = 0
          } else {
            if(this.$route.path == '/mobileHome') {
              this.bg = 1
            } else {
              this.bg = 0
            }
          }
        },
        immediate: true
      }
    },
    methods: {
      toLink(e) {
        localStorage.setItem("current_page", e)
        this.showMenu = false
        this.$router.push({
          path: e
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.container{
  img{
    width: 100%;
    height: 100%;
    display: block;
  }
  .placeholder-box{
    width: 100%;
    height: 1.24rem;
  }
  .head{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.32rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .img-logo{
      width: 3.6rem;
      height: 0.6rem;
    }
    .img-icon{
      width: 0.4rem;
      height: 0.4rem;
    }
    .menu{
      position: absolute;
      top: calc(100% - 0.28rem);
      right: 0.36rem;
      background: #fff;
      border-radius: 0.1rem;
      border: 2px solid #0f62fe33;
      padding: 0 0.3rem;
      .menu-opt{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .menu-icon{
          width: 0.36rem;
          height: 0.36rem;
          display: block;
          margin-right: 0.2rem;
        }
        .menu-title{
          font-size: 0.28rem;
          color: #333333;
          padding: 0.2rem 0;
          border-bottom: 1px solid #EAEAEA;
        }
        .menu-title:last-child{
          border-bottom: none
        }
      }
    }
  }
}
</style>