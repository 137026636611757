<template>
  <div class="equityGoods">
    <div class="equityGoods_bgm">
      <img
        :src="`${$link}/webimg/equityGoods_img.png`"
        alt=""
      />
    </div>
    <div style="background: #f8fafc; width: 100%">
      <div class="equity_tabs">
        <span
          v-for="(item, index) in tabsList"
          :key="index"
          @click="itemClcik(index)"
          :class="active === index ? 'active' : ''"
          >{{ item }}</span
        >
      </div>
      <div class="brand_box">
        <div class="brand_item" v-if="active == 0">
          <img
            :src="`${$link}/webimg/tab1.png`"
            alt=""
          />
        </div>
        <div class="brand_item" v-if="active == 1">
          <img
            :src="`${$link}/webimg/tab2.png`"
            alt=""
          />
        </div>
        <div class="brand_item" v-if="active == 2">
          <img
            :src="`${$link}/webimg/tab3.png`"
            alt=""
          />
        </div>
        <div class="brand_item" v-if="active == 3">
          <img
            :src="`${$link}/webimg/tab4.png`"
            alt=""
          />
        </div>
        <div class="brand_item" v-if="active == 4">
          <img
            :src="`${$link}/webimg/tab5.png`"
            alt=""
          />
        </div>
        <div class="brand_item" v-if="active == 5">
          <img
            :src="`${$link}/webimg/tab6.png`"
            alt=""
          />
        </div>
        <div class="brand_item" v-if="active == 6">
          <img
            :src="`${$link}/webimg/tab7.png`"
            alt=""
          />
        </div>
        <div class="brand_item" v-if="active == 7">
          <img
            :src="`${$link}/webimg/tab8.png`"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="interaction_Box">
      <p>全品类商品供应链</p>
      <span> 融合线上线下权益场景，生活实用、吃喝玩乐，样样都有 </span>
      <div></div>
    </div>
    <div class="card_box">
      <div class="card_box_item">
        <img
          :src="`${$link}/webimg/card_1.png`"
          class="card_box_item_top"
          alt=""
        />
        <div class="card_box_item_bom">
          <span>产品权益</span>
          <p>涵盖多种品类，品类齐全，加速构筑 企业权益新生态</p>
        </div>
      </div>
      <div class="card_box_item">
        <img
          :src="`${$link}/webimg/card_2.png`"
          class="card_box_item_top"
          alt=""
        />
        <div class="card_box_item_bom">
          <span>多维场景</span>
          <p>覆盖银行、保险、通信、互联网等多 维场景，满足企业的真正需求</p>
        </div>
      </div>
      <div class="card_box_item">
        <img
          :src="`${$link}/webimg/card_3.png`"
          class="card_box_item_top"
          alt=""
        />
        <div class="card_box_item_bom">
          <span>消费卡券</span>
          <p>生活旅游、优惠券、异业合作，用积 分乐享吃喝玩乐</p>
        </div>
      </div>
      <div class="card_box_item">
        <img
          :src="`${$link}/webimg/card_4.png`"
          class="card_box_item_top"
          alt=""
        />
        <div class="card_box_item_bom">
          <span>虚拟充值</span>
          <p>话费、游戏、视频会员，用积分体验 数字生活</p>
        </div>
      </div>
    </div>
    <div class="interaction_Box">
      <p>我们的优势</p>
      <span> 从商品、渠道到运营和技术，我们拥有行业领先实力 </span>
      <div></div>
    </div>
    <div class="advantage_box">
      <div class="advantage_item">
        <img
          :src="`${$link}/webimg/advantage_bgm1.png`"
          alt=""
        />
        <span>商品优势</span>
        <p>累计提供22,000+数字权益</p>
      </div>
      <div class="advantage_item">
        <img
          :src="`${$link}/webimg/advantage_bgm2.png`"
          alt=""
        />
        <span>渠道优势</span>
        <p>遍布全行业的渠道布局 占领互联网各个流量入口</p>
      </div>
      <div class="advantage_item">
        <img
          :src="`${$link}/webimg/advantage_bg3.png`"
          alt=""
        />
        <span>运营优势</span>
        <p>从数字商品交易 深度运营到搭建完整营销体系</p>
      </div>
      <div class="advantage_item">
        <img
          :src="`${$link}/webimg/advantage_bg4.png`"
          alt=""
        />
        <span>技术优势</span>
        <p>行业领先的技术实力 专业研发人员</p>
      </div>
    </div>
    <!-- 回到顶部 -->
    <toTop />
  </div>
</template>
<script>
export default {
  name: 'equityGoods',
  data () {
    return {
      active: 0,
      tabsList: [
        '立减金',
        '充值缴费',
        '影音娱乐',
        '购物权益',
        '生活服务',
        '美食饮品',
        '机酒出行',
        '知识阅读'
      ]
    }
  },
  methods: {
    itemClcik (index) {
      this.active = index
      // this.$emit('tabsClick', index)
    }
  }
}
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 18px;
}
.equityGoods {
  .equityGoods_bgm {
    width: 100%;
    height: 600px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .equity_tabs {
    margin: 0 auto;
    width: 1200px;
    height: 80px;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    span {
      display: block;
      width: 113px;
      height: 40px;
      box-sizing: border-box;
      padding: 9px 20px;
    }
    &:hover {
      cursor: default;
    }
    .active {
      width: 113px;
      height: 40px;
      background: #00a0fb;
      border-radius: 5px;
      color: #fff;
    }
  }
  .brand_box {
    width: 1200px;
    height: 445px;
    margin: 0 auto;
    padding-bottom: 100px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto auto auto auto auto;
    // 控制行间距
    grid-row-gap: 15px;
    .brand_item {
      width: 1200px;
      height: 445px;
      border-radius: 10px;
      img {
        width: 1200px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .interaction_Box {
    margin: 0;
    height: 260px;
    box-sizing: border-box;
    padding-top: 100px;
    position: relative;
    p {
      // height: 42px;
      font-weight: 700;
      font-size: 30px;
      color: #333333;
    }
    span {
      margin-top: 15px;
      display: inline-block;
      color: #00a0fb;
    }
    div {
      display: inline-block;
      width: 86px;
      height: 4px;
      position: absolute;
      top: 197px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #00a0fb;
      border-radius: 2px;
    }
  }
  .card_box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .card_box_item {
      width: 286px;
      height: 437px;
      //   background-color: skyblue;
      border-radius: 10px 10px 0 0;
      .card_box_item_top {
        display: block;
        width: 286px;
        height: 260px;
      }
      .card_box_item_bom {
        box-sizing: border-box;
        width: 286px;
        height: 177px;
        border: 1px solid #e0e0e0;
        border-radius: 0 0 10px 10px;
        span {
          display: inline-block;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          position: relative;
          margin: 40px 0 30px 0;
          &::after {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            background: #00a0fb;
            border-radius: 1.5px;
            position: absolute;
            top: 35px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        p {
          display: inline-block;
          width: 237px;
          height: 40px;
          text-align: center;
          font-weight: 500;
          font-size: 14px;
          color: #777777;
          letter-spacing: 0.78px;
        }
      }
    }
  }
  .advantage_box {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 100px;
    // background-color: skyblue;
    display: flex;
    justify-content: space-between;
    .advantage_item {
      width: 286px;
      height: 286px;
      background: #f8fafc;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      //   justify-content: center;
      align-items: center;
      img,
      span,
      p {
        display: block;
      }
      img {
        width: 89px;
        height: 89px;
        margin-top: 46px;
      }
      span {
        width: 76px;
        height: 25px;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        margin-top: 30px;
      }
      p {
        width: 178px;
        height: 20px;
        font-weight: 500;
        font-size: 14px;
        color: #777777;
        margin-top: 10px;
      }
    }
  }
}
</style>
