<template>
  <div class="mobile-foot">
    <div>Copyright©2009-2022 深圳赛迪文信息科技有限公司</div>
    <div>ICP备案号：<span @click="openLink">粤ICP备2022135991号</span></div>
    <div>电信增值许可证：粤B2-20230695</div>
  </div>
</template>

<script>
export default {
  name: 'mobileFoot',
  methods: {
    openLink () {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }
  }
}
</script>

<style lang="less" scoped>
.mobile-foot {
  height: 2rem;
  background: #41434c;
  color: #fff;
  font-size: 0.24rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0.2rem 0;
  box-sizing: border-box;
}
</style>
