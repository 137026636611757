function IsPC() {
  var userAgentInfo = navigator.userAgent;
  console.log('userAgentInfo ==>', userAgentInfo)
  var Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
      }
  }
  return flag;
}

export default IsPC