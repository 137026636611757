<template>
  <div class="container-form">
    <div class="form-box">
      <div class="title">获取资料和报价</div>
      <div class="opt-box">
        <div class="tip">公司名称</div>
        <input type="text" v-model="companyName" placeholder="请输入公司名称">
      </div>
      <div class="opt-box">
        <div class="tip">联系人</div>
        <input type="text" v-model="contact" placeholder="请输入联系人">
      </div>
      <div class="opt-box">
        <div class="tip">手机号</div>
        <input type="text" v-model="phone" placeholder="请输入手机号">
      </div>
      <div class="name">您想要了解的权益或营销场景</div>
      <div class="textarea">
        <textarea class="area" v-model="message" placeholder="请输入您想要了解的权益或营销场景"></textarea>
      </div>
      <div class="btn" v-preventReClick="submit">获取资料和报价</div>
    </div>
    <mobile-toast :msg="tmsg" :state="state" />
  </div>
</template>

<script>
import MobileToast from './mobileToast.vue'
  export default {
    name: "mobileForm",
    data() {
      return {
        companyName: '',
        contact: '',
        phone: '',
        message: '',
        tmsg: '',
        state: ''
      }
    },
    components: {
      MobileToast,
    },
    methods: {
      submit() {
        const pattern = /^1[3456789]\d{9}$/;
        if(!this.companyName || !this.contact || !this.phone || !this.message) {
          this.tmsg = '请输入完整的信息'
          this.state = 'tips'
        } else if (!pattern.test(this.phone)) {
          this.tmsg = '请输入正确的手机号'
          this.state = 'tips'
        } else {
          this.tmsg = "加载中..."
          this.state = 'loading'
          let obj = {
            companyName: this.companyName,
            userName: this.contact, //用户姓名
            phoneNumber: this.phone, //手机号
            cooperationIntention: this.message, //合作意向
          }
          this.$api.intentionToCooperate(obj)
          .then(res => {
            if(res.code == 200) {
              this.tmsg = '提交成功'
              this.state = 'succeed'
              this.companyName = '';
              this.contact = '';
              this.phone = '';
              this.message = '';
            } else {
              this.tmsg = '提交失败'
              this.state = 'fail'
            }
          })
        }
        setTimeout(() => {
          this.state = ''
        }, 1000)
      }
    }
  }
</script>

<style lang="less" scoped>
.container-form{
  padding: 0 0.32rem;
  .form-box{
    background: #fff;
    border-radius: 0.2rem;
    padding: 0.4rem;
    .title{
      font-size: 0.36rem;
      font-weight: 500;
      color: #333333;
      line-height: 0.5rem;
      margin-bottom: 0.2rem;
    }
    .opt-box{
      display: flex;
      align-items: center;
      padding: 0.4rem 0;
      border-bottom: 0.02rem solid #0000000d;
      .tip{
        width: 1.5rem;
        font-size: 0.28rem;
        line-height: 0.4rem;
        color: #999999;
        text-align: left;
      }
      input{
        border: none;
        color: #333333;
        width: calc(100% - 1.50rem);
        font-size: 0.32rem;
        outline: none;
      }
    }
    .name{
      font-size: 0.28rem;
      color: #555555;
      line-height: 0.4rem;
      text-align: left;
      padding: 0.4rem 0 0.2rem;
    }
    .textarea{
      border: 0.02rem solid #E1E1E1;
      border-radius: 0.1rem;
      padding: 0.2rem;
      .area{
        width: 100%;
        height: 2rem;
        border: none;
        padding: 0;
        font-size: 0.28rem;
        outline: none;
        color: #333333;
      }
    }
    .btn{
      width: 100%;
      height: 1rem;
      background: #0F62FE;
      color: #fff;
      font-size: 0.36rem;
      line-height: 1rem;
      border-radius: 0.1rem;
      margin-top: 0.4rem;
    }
  }
}
</style>