<template>
  <div class="entitlement-goods">
    <img class="equity-top" :src="`${$link}/h5img/equity01.png`" alt="" srcset="">
    <div class="carousel">
      <div class="flex-box">
        <div class="bank-box" :style="{color: `${index == selected ? '#0F62FE' : '#333333'}`}" @click="choose(index)" v-for="(item, index) in list" :key="index">
          <div class="name">{{item}}</div>
          <div class="line" :style="{background: `${index == selected ? '#0F62FE' : 'transparent' }`}"></div>
        </div>
      </div>
    </div>
    <div class="brand-box">
      <img class="brand-img" :src="`${$link}/h5img/commodity0${selected + 1}.png`" alt="">
    </div>

    <mobile-title-two title="全品类商品供应链" message="融合线上线下权益场景，生活实用、吃喝玩乐，样样都有" />

    <div class="supply-chain">
      <div class="supply-item" v-for="item in supplyChain" :key="item.id">
        <img class="supply-item-img" :src="item.img" alt="">
        <div class="supply-item-title">{{item.title}}</div>
        <div class="supply-item-line"></div>
        <div class="supply-item-info">{{item.massage}}</div>
      </div>
    </div>

    <mobile-title-two title="我们的优势" message="从商品、渠道到运营和技术，我们拥有行业领先实力" />
    <div class="advantage-box">
      <div class="advantage-item" v-for="item in advantageList" :key="item.id">
        <img class="advantage-item-img" :src="item.img" alt="">
        <div class="advantage-item-title">{{item.title}}</div>
        <div class="advantage-item-info">{{item.massage}}</div>
      </div>
    </div>

    <mobile-foot />

  </div>
</template>

<script>
import mobileTitleTwo from '@/components/mobileTitleTwo.vue'
import MobileFoot from '@/components/mobileFoot.vue'
  export default {
    name: "entitlementGoods",
    data() {
      return {
        selected: 0,
        list: ['立减金', '充值缴费', '影音娱乐', '购物权益', '生活服务', '美食饮品','机酒出行', '知识阅读'],
        supplyChain: [
          {
            id: 1,
            title: '权益产品',
            img: this.$link + "/h5img/equity03.png",
            massage: '涵盖多种品类，品类齐全，加速构筑企业权益新生态',
            
          },
          {
            id: 2,
            title: '多维场景',
            img: this.$link + "/h5img/equity04.png",
            massage: '覆盖银行、保险、通信、互联网等多维场景，满足企业的真正需求',
            
          },
          {
            id: 3,
            title: '消费卡券',
            img: this.$link + "/h5img/equity05.png",
            massage: '生活旅游、优惠券、异业合作，用积分乐享吃喝玩乐',
            
          },
          {
            id: 4,
            title: '虚拟充值',
            img: this.$link + "/h5img/equity06.png",
            massage: '话费、游戏、视频会员，用积分体验数字生活',
            
          }
        ],
        advantageList: [
          {
            id: 1,
            title: '商品优势',
            img: this.$link + "/h5img/equity07.png",
            massage: '累计提供22,000+数字权益',
            
          },
          {
            id: 2,
            title: '渠道优势',
            img: this.$link + "/h5img/equity08.png",
            massage: '遍布全行业的渠道布局占领互联网各个流量入口',
            
          },
          {
            id: 3,
            title: '运营优势',
            img: this.$link + "/h5img/equity09.png",
            massage: '从数字商品交易深度运营到搭建完整营销体系',
            
          },
          {
            id: 4,
            title: '技术优势',
            img: this.$link + "/h5img/equity10.png",
            massage: '行业领先的技术实力专业研发人员',
            
          }
        ]
      }
    },
    components: { mobileTitleTwo, MobileFoot },
    methods: {
      choose(e) {
        this.selected = e
      }
    }
  }
</script>

<style lang="less" scoped>
.entitlement-goods{
  img{
    display: block;
  }
  .equity-top{
    width: 100%;
    height: 4.4rem;
  }
  .carousel{
    overflow-x: scroll;
    // margin: 0 0.36rem;
    padding: 0 0.2rem;
    .flex-box{
      color: #555555;
      font-size: 0.28rem;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      height: 1.14rem;
      .bank-box{
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 0.2rem;
        .name{
          line-height: 0.4rem;
        }
        .line{
          width: 0.56rem;
          height: 0.04rem;
          margin-top: 0.1rem;
          border-radius: 0.02rem;
        }
      }
    }
  }
  .brand-box{
    margin: 0 0.36rem;
    .brand-img{
      width: 100%;
    }
  }
  .supply-chain{
    margin: 0 0.36rem;
    .supply-item{
      overflow: hidden;
      border: 1px solid #E0E0E0;
      border-radius: 0.2rem;
      box-sizing: border-box;
      margin-top: 0.3rem;
      .supply-item-img{
        width: 100%;
        display: block;
      }
      .supply-item-title{
        font-size: 0.36rem;
        color: #333333;
        letter-spacing: 1px;
        margin-top: 0.8rem;
      }
      .supply-item-line{
        width: 0.4rem;
        height: 0.04rem;
        background: #0F62FE;
        border-radius: 0.02rem;
        margin: 0.2rem auto 0;
      }
      .supply-item-info{
        margin: 0.4rem 1.06rem 0.8rem;
        font-size: 0.28rem;
        color: #777777;
        letter-spacing: 1px;
      }
    }
  }
  .advantage-box{
    margin: 0 0.36rem 0.4rem;
    .advantage-item{
      overflow: hidden;
      background: #F8FAFC;
      border: 1px solid #0f62fe33;
      border-radius: 0.20rem;
      padding: 0.9rem 0;
      margin-top: 0.3rem;
      .advantage-item-img{
        width: 1.78rem;
        height: 1.78rem;
        display: block;
        margin: 0 auto;
      }
      .advantage-item-title{
        font-size: 0.36rem;
        color: #333333;
        letter-spacing: 1px;
        margin-top: 0.6rem;
      }
      .advantage-item-info{
        font-size: 0.28rem;
        color: #777777;
        letter-spacing: 1px;
        margin: 0.2rem 1.5rem 0;
        line-height: 0.4rem;
      }
    }
  }
}
</style>