<template>
  <div class="modeSupply">
    <div class="supply-box">
      <div class="line">
        <div class="opt">
          <img :src="`${$link}/h5img/home07.png`" alt="">
          <div class="name">邮件供货</div>
        </div>
        <div class="opt">
          <img :src="`${$link}/h5img/home08.png`" alt="">
          <div class="name">API系统对接</div>
        </div>
        <div class="opt">
          <img :src="`${$link}/h5img/home09.png`" alt="">
          <div class="name">手机号短信发送</div>
        </div>
      </div>
      <div class="line">
        <div class="opt">
          <img :src="`${$link}/h5img/home12.png`" alt="">
          <div class="name">管理员发奖工具</div>
        </div>
        <div class="opt">
          <img :src="`${$link}/h5img/home11.png`" alt="">
          <div class="name">积分商城</div>
        </div>
        <div class="opt">
          <img :src="`${$link}/h5img/home10.png`" alt="">
          <div class="name">H5活动页面</div>
        </div>
      </div>
      <div class="line">
        <div class="opt">
          <img :src="`${$link}/h5img/home13.png`" alt="">
          <div class="name">小程序/H5领奖工具</div>
        </div>
        <div class="opt">
          <img :src="`${$link}/h5img/home14.png`" alt="">
          <div class="name">定制化供货方案</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "mobileModeSupply"
  }
</script>

<style lang="less" scoped>
.modeSupply{
  margin: 0 0.36rem;
  background: #fff;
  border-radius: 0.2rem;
  .supply-box{
    padding: 0.1rem 0.4rem 0.4rem;
    .line{
      margin-top: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .opt{
        flex: 1;
        img{
          width: 1.12rem;
          height: 1.12rem;
          display: block;
          margin: 0 auto;
        }
        .name{
          color: #333333;
          font-size: 0.24rem;
          margin-top: 0.1rem;
        }
      }
    }
  }
}
</style>