<template>
  <div class="container">
    <mobile-head :scrollTop="oldScrollTop"/>
    <keep-alive>
      <router-view v-if="$route.meta.keep"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keep"></router-view>
  </div>
</template>

<script>
import mobileHead from "@/components/mobileHead.vue";

export default {
  name: "mobilIndex",
  components: { mobileHead },
  data() {
    return {
      oldScrollTop: 0
    }
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.windonScroll);
  },
  methods: {
    windonScroll() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.oldScrollTop = scrollTop;
    },
  },
};
</script>

<style lang="less" scoped>
</style>