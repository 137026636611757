<template>
  <div class="open-platform">
    <img
      class="open-top"
      :src="`${$link}/h5img/open01.png`"
      alt=""
      srcset=""
    />

    <mobile-title-two
      title="悦动权益 数字权益商品API接口接入流程"
      message="只需简单的几个步骤，即可快速接入数字权益商品API"
    />
    <div class="process-box">
      <div class="process-item" v-for="item in processList" :key="item.id">
        <img class="process-item-img img-wh24" :src="item.img" alt="" />
        <div class="process-item-title title-mt20">{{ item.title }}</div>
        <div class="process-item-info">{{ item.massage }}</div>
      </div>
    </div>

    <mobile-title-two
      class="title-two"
      title="数字权益商品API接口应用场景"
      message="数字权益商品API接口可广泛应用于营销、
福利、积分兑换等场景"
    />
    <div class="process-box">
      <div class="process-item" v-for="item in scenarioList" :key="item.id">
        <img class="process-item-img img-wh32" :src="item.img" alt="" />
        <div class="process-item-title title-mt20">{{ item.title }}</div>
        <div class="process-item-info">{{ item.massage }}</div>
      </div>
    </div>

    <mobile-title-two
      class="title-funct"
      title="悦动权益 商品API接口平台主要功能"
      message="数字权益商品API接口可广泛应用于营销、福利、
积分兑换等场景"
    />
    <div class="process-box">
      <div
        class="process-item border-type p-b100"
        v-for="item in keyFeaturesList"
        :key="item.id"
      >
        <img class="process-item-img img-wh57" :src="item.img" alt="" />
        <div class="two-line"></div>
        <div class="process-item-title">{{ item.title }}</div>
        <div class="process-item-info info-mlr36">{{ item.massage }}</div>
      </div>
    </div>

    <mobile-title-two
      class="title-platform"
      title="数字权益商品API接口优势"
      message="多年的功能迭代与技术创新，众多客户服务经验的沉淀，造就了可靠、安全、高效的数字商品API接口平台"
    />
    <div class="process-box">
      <div class="process-item" v-for="item in platformList" :key="item.id">
        <img class="process-item-img img-wh24" :src="item.img" alt="" />
        <div class="process-item-title title-mt20">{{ item.title }}</div>
        <div class="process-item-info">{{ item.massage }}</div>
      </div>
    </div>

    <mobile-foot />
  </div>
</template>

<script>
import mobileTitleTwo from '@/components/mobileTitleTwo.vue'
import MobileFoot from '@/components/mobileFoot.vue'

export default {
  name: 'openPlatform',
  data () {
    return {
      processList: [
        {
          id: 1,
          title: '联系业务经理',
          img: this.$link + '/h5img/open02.png',
          massage: '洽谈合作事宜，开通平台账号'
        },
        {
          id: 2,
          title: 'API接口接入',
          img: this.$link + '/h5img/open03.png',
          massage: '对接接口进行调试，悦动权益提供免费技术支持'
        },
        {
          id: 3,
          title: '数字权益商品配置',
          img: this.$link + '/h5img/open04.png',
          massage: '配置权益商品种类、单价等信息'
        },
        {
          id: 4,
          title: '正式使用',
          img: this.$link + '/h5img/open05.png',
          massage: '通过接口正式调用'
        }
      ],
      scenarioList: [
        {
          id: 1,
          title: '引流活动',
          img: this.$link + '/h5img/open06.png',
          massage:
            '企业的拉新促活营销活动中，通过API接口实时调用数字商品，提升营销效果与用户体验'
        },
        {
          id: 2,
          title: '积分商城',
          img: this.$link + '/h5img/open07.png',
          massage:
            '企业的积分商城接入数字权益商品API，可增加商品品类、无需考虑库存与物流，极大节约运营成本'
        },
        {
          id: 3,
          title: '企业福利',
          img: this.$link + '/h5img/open08.png',
          massage:
            '企业、工会内部福利平台接入权益商品API，为员工提供更多商品选择，提升员工满意度'
        }
      ],
      keyFeaturesList: [
        {
          id: 1,
          title: '数字权益商品管理',
          img: this.$link + '/h5img/open09.png',
          massage:
            '可对接入的向品按失型进行管理，可自助合询向品信息、价格信息、面值信息、阵存情况、商品属性等'
        },
        {
          id: 2,
          title: '数字权益商品订单管理',
          img: this.$link + '/h5img/open10.png',
          massage:
            '可自助音询向品订单明细，可自助在线对账，可在线生成图形化统计报表，在线管理发票开具情'
        },
        {
          id: 3,
          title: '数字权益商品系统配置',
          img: this.$link + '/h5img/open11.png',
          massage:
            '可自行配居平台登录验证、余额提醒、IP白名单、管理员手机绑定等'
        }
      ],
      platformList: [
        {
          id: 1,
          title: '充值秒到账',
          img: this.$link + '/h5img/open12.png',
          massage: '直连品牌商，重组商品库存，商品充值秒到账'
        },
        {
          id: 2,
          title: '丰富的数字权益商品',
          img: this.$link + '/h5img/open13.png',
          massage: '覆盖全品类的数字权益商品品类，满足用户各场景需求'
        },
        {
          id: 3,
          title: '自助对账',
          img: this.$link + '/h5img/open14.png',
          massage: '在线生成订单报告，可在线自助对账'
        },
        {
          id: 4,
          title: '失败自动补单',
          img: this.$link + '/h5img/open15.png',
          massage: '实时侦测充值状态，失败自动补单，提升成功率'
        },
        {
          id: 5,
          title: '多个资源自动路由',
          img: this.$link + '/h5img/open16.png',
          massage: '动态监控各资源线路健康状况，如遇到异常自动路由'
        },
        {
          id: 6,
          title: '专用短信通道',
          img: this.$link + '/h5img/open17.png',
          massage: '专用短信通道发送券码，安全、快速、稳定'
        }
      ]
    }
  },
  components: { mobileTitleTwo, MobileFoot }
}
</script>

<style lang="less" scoped>
.open-platform {
  .open-top {
    width: 100%;
    display: block;
  }
  .title-two {
    margin: 0 1.3rem;
  }
  .title-funct {
    margin: 0 0.9rem;
  }
  .title-platform {
    margin: 0.7rem 0.5rem 0;
  }

  .process-box {
    margin: 0 0.36rem 0.4rem;
    .border-type {
      overflow: hidden;
      background: #f8fafc;
      border: 1px solid #0f62fe33;
      border-radius: 0.2rem;
      margin-top: 0.4rem;
    }
    .process-item {
      padding: 0.3rem 0;
      margin-bottom: 0.2rem;
      .process-item-img {
        display: block;
        margin: 0 auto;
      }
      .img-wh24 {
        width: 2.4rem;
        height: 2.4rem;
      }
      .img-wh32 {
        width: 3.2rem;
        height: 3.2rem;
      }
      .img-wh57 {
        width: 5.12rem;
        height: 3.7rem;
        margin-top: 0.32rem;
      }
      .process-item-title {
        font-size: 0.32rem;
        color: #333333;
        letter-spacing: 1px;
      }
      .title-mt20 {
        margin-top: 0.2rem;
      }
      .title-mt40 {
        margin-top: 0.4rem;
      }
      .two-line {
        height: 0.08rem;
        background: #0f62fe;
        border-radius: 0.04rem;
        margin: 0.4rem auto 0.6rem;
        width: 1.2rem;
      }
      .process-item-info {
        font-size: 0.28rem;
        color: #777777;
        letter-spacing: 1px;
        margin: 0.2rem 0 0;
        line-height: 0.4rem;
      }
      .info-mlr36 {
        margin-left: 0.36rem;
        margin-right: 0.36rem;
      }
    }
    .p-b100 {
      padding-bottom: 1rem;
    }
  }
}
</style>
