<template>
  <div class="container" ref="mianscroll">
    <video
      src="../../assets/web-imges/flower.mp4"
      style="
        width: 100%;
        height: 800px;
        margin-top: 80px;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      "
      id="videoId"
      autoplay="autoplay"
      loop="loop"
      muted="muted"
    ></video>
    <div class="banner">
      <div class="banner_img">
        <img
          :src="`${$link}/webimg/quanyi.png`"
          alt=""
          style="width: 100%; height: 100%; display: block"
        />
        <span class="banner_text">科技赋能·定制未来</span>
      </div>
    </div>
    <div class="interaction_Box">
      <p>悦动营销权益平台</p>
      <span>
        [工具+服务+营销]一步到位，一站式解决方案，让营销简单、精准、有效
      </span>
      <div></div>
    </div>
    <div class="interaction_tabs">
      <div
        class="interaction_tabs_item"
        :class="equityIndex == 0 ? 'active_qy_tabs0' : ''"
        @click="equityClick(0)"
      >
        <div class="interaction_tabs_item_top">
          <img
            :src="`${$link}/webimg/equity_1.png`"
            v-if="equityIndex == 0"
            alt=""
          />
          <img :src="`${$link}/webimg/equity_16.png`" v-else alt="" />
          <span>营销工具</span>
        </div>
        <div class="interaction_tabs_item_centre">
          <p>
            为企业提供海量热点营销组合模式，同时快速实现营销工具创建、裂变营销及权益下发等一体化解决方案;支持企业个性化营销活动，按需定制，驱动企业实现业绩增长
          </p>
        </div>
        <div class="interaction_tabs_item_bom">
          <span>企业福利发奖</span>
          <span>轮盘抽奖</span>
          <span>短信发奖</span>
          <span>消息发奖</span>
        </div>
      </div>
      <div
        class="interaction_tabs_item"
        :class="equityIndex == 1 ? 'active_qy_tabs1' : ''"
        @click="equityClick(1)"
      >
        <div class="interaction_tabs_item_top">
          <img
            :src="`${$link}/webimg/equity_2.png`"
            v-if="equityIndex == 1"
            alt=""
          />
          <img :src="`${$link}/webimg/equity_17.png`" v-else alt="" />
          <span>权益商品</span>
        </div>
        <div class="interaction_tabs_item_centre">
          <p>
            严苛选品，与优质供应商合作，提供包含美食、饮品、出行、阅读、生活服务等口碑极佳的品牌商品。支持AP权益下单，码券兑换、微信红包、微信立减金、支付宝消费红包、美团红包等全媒体营销互动
          </p>
        </div>
        <div class="interaction_tabs_item_bom">
          <span>微信红包</span>
          <span>微信立减金</span>
          <span>支付宝红包</span>
          <span>美团红包</span>
          <span>云闪付立减金</span>
        </div>
      </div>
      <div
        class="interaction_tabs_item"
        :class="equityIndex == 2 ? 'active_qy_tabs2' : ''"
        @click="equityClick(2)"
      >
        <div class="interaction_tabs_item_top">
          <img
            :src="`${$link}/webimg/equity_3.png`"
            v-if="equityIndex == 2"
            alt=""
          />
          <img :src="`${$link}/webimg/equity_18.png`" v-else alt="" />
          <span>技术研发</span>
        </div>
        <div class="interaction_tabs_item_centre">
          <p>
            强大的技术团队，为多渠道业务场景保障护航，支持微信公众号、小程序、H5活动定制化开发，为开发者提供强悍活动支撑能力。
          </p>
        </div>
        <div class="interaction_tabs_item_bom">
          <span>多场景护航</span>
          <span>定制化开发</span>
          <span>专业技术支持</span>
        </div>
      </div>
    </div>
    <!-- 服务保障 -->
    <div class="safeguard">
      <div class="interaction_Box">
        <p style="color: #ffffff">悦动权益提供全面服务保障</p>
        <span style="color: #ffffff"> 全方位一站式服务 </span>
        <div style="background-color: #ffffff"></div>
      </div>

      <div class="swiper-box">
        <swiper ref="mySwiper" :options="onSlideChange">
          <swiper-slide>
            <div class="swiper_item">
              <div class="swiper_item_top">
                <img :src="`${$link}/webimg/file3.png`" alt="" />
              </div>
              <div class="swiper_item_bom">
                <a style="background: #0f62fe"></a>
                <span>海量权益商品</span>
                <p>
                  目的是提供更便捷、快速、个性化的服务，增强客户的满意度和忠诚度。通过不同的沟通方式，客户可以选择最适合自己需求的联系方式，客户可以选择最适合自己需求的联系方式，并随时获得帮助。
                </p>
                <div class="swiper_item_bom_btn">
                  <div
                    class="swiper_item_bom_btn_let"
                    style="color: #0f62fe; border: 1px solid #0f62fe"
                  >
                    电商式管理
                  </div>
                  <div
                    class="swiper_item_bom_btn_rig"
                    style="color: #0f62fe; border: 1px solid #0f62fe"
                  >
                    快速供应
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper_item">
              <div class="swiper_item_top">
                <img :src="`${$link}/webimg/file5.png`" alt="" />
              </div>
              <div class="swiper_item_bom">
                <a style="background: #f68e56"></a>
                <span>定制化服务</span>
                <p>
                  平台产品模块化支持灵活配置，我们拥有专业的技术团队，可以根据企业的需求，基于深度交互进行服务定制或模块开发数据分析，从而帮助您更快地实现业务目标。
                </p>
                <div class="swiper_item_bom_btn">
                  <div
                    class="swiper_item_bom_btn_let"
                    style="color: #f68e56; border: 1px solid #f68e56"
                  >
                    定制化
                  </div>
                  <div
                    class="swiper_item_bom_btn_rig"
                    style="color: #f68e56; border: 1px solid #f68e56"
                  >
                    灵活配置
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper_item">
              <div class="swiper_item_top">
                <img :src="`${$link}/webimg/file2.png`" alt="" />
              </div>
              <div class="swiper_item_bom">
                <a style="background: #00a0fb"></a>
                <span>顾问式运营服务</span>
                <p>
                  我们提供顾问式运营服务，帮助实现业务增长，释放团队潜力，让企业在竞争中脱颖而出。与我们合作，开启通向成功的策略之旅
                </p>
                <div class="swiper_item_bom_btn">
                  <div
                    class="swiper_item_bom_btn_let"
                    style="color: #00a0fb; border: 1px solid #00a0fb"
                  >
                    全链路追踪
                  </div>
                  <div
                    class="swiper_item_bom_btn_rig"
                    style="color: #00a0fb; border: 1px solid #00a0fb"
                  >
                    全程指导
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper_item">
              <div class="swiper_item_top">
                <img :src="`${$link}/webimg/file4.png`" alt="" />
              </div>
              <div class="swiper_item_bom">
                <a style="background: #0f62fe"></a>
                <span>技术保障</span>
                <p>
                  我们提供可靠的技术保障，让企业业务及技术支撑始终高效运转。技术专家团队随时待命解决问题、预防风险，并确保系统安全稳定。
                </p>
                <div class="swiper_item_bom_btn">
                  <div
                    class="swiper_item_bom_btn_let"
                    style="color: #0f62fe; border: 1px solid #0f62fe"
                  >
                    系统稳定
                  </div>
                  <div
                    class="swiper_item_bom_btn_rig"
                    style="color: #0f62fe; border: 1px solid #0f62fe"
                  >
                    应急响应
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper_item">
              <div class="swiper_item_top">
                <img :src="`${$link}/webimg/file6.png`" alt="" />
              </div>
              <div class="swiper_item_bom">
                <a style="background: #f68e56"></a>
                <span>数据分析</span>
                <p>
                  我们专注于数据分析，通过挖掘数据中的信息，深入洞察，协助企业做出智慧决策、数据驱动，定制未来。
                </p>
                <div class="swiper_item_bom_btn">
                  <div
                    class="swiper_item_bom_btn_let"
                    style="color: #f68e56; border: 1px solid #f68e56"
                  >
                    数据驱动
                  </div>
                  <div
                    class="swiper_item_bom_btn_rig"
                    style="color: #f68e56; border: 1px solid #f68e56"
                  >
                    定制未来
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper_item">
              <div class="swiper_item_top">
                <img :src="`${$link}/webimg/file1.png`" alt="" />
              </div>
              <div class="swiper_item_bom">
                <a></a>
                <span>多渠道在线客服</span>
                <p>
                  目的是提供更便捷、快速、个性化的服务，增强客户的满意度和忠诚度。通过不同的沟通方式，客户可以选择最适合自己需求的联系方式，客户可以选择最适合自己需求的联系方式，并随时获得帮助。
                </p>
                <div class="swiper_item_bom_btn">
                  <div class="swiper_item_bom_btn_let">7*24小时</div>
                  <div class="swiper_item_bom_btn_rig">应急响应</div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        </swiper>
        <div
          class="swiper-btn-left"
          style="color: #e0e0e0"
          slot="pagination"
        ></div>
        <div
          class="swiper-btn-right"
          style="color: #e0e0e0"
          slot="pagination"
        ></div>
      </div>
    </div>
    <div class="interaction_Box">
      <p>经典客户案例</p>
      <span> 权益解决方案涵盖银行、保险、通信、互联网等多个领域 </span>
      <div></div>
    </div>
    <div class="case">
      <div class="case_box">
        <div v-for="(item, index) in bankTexts" :key="index">
          <div class="case_item" v-if="caseIndex == index">
            <span class="case_comma"></span>
            <p>
              {{ item }}
            </p>
            <button @click="toLink('/webCase')">
              <span>银行解决方案 ></span>
            </button>
            <div class="bank_item_bg1">
              <img
                :src="`${$link}/webimg/bank_logo_bg${index + 1}.png`"
                alt=""
              />
            </div>
            <div class="bank_item_bg2">
              <img
                :src="`${$link}/webimg/bank_logo_bgtab${index + 1}.png`"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="case_tabs">
      <div
        v-for="(item, index) in 15"
        :key="index"
        class="case_tabs_item"
        :class="caseIndex == index ? 'case_active' : ''"
        @click="caseTabsClick(index)"
      >
        <img :src="`${$link}/webimg/bank_logo_tab${index + 1}.png`" alt="" />
      </div>
      <span class="more" @click="toLink('/webCase')">查看更多合作案例 ></span>
    </div>
    <div class="brand_box">
      <div class="interaction_Box">
        <p>权益品牌</p>
        <span> 权益解决方案涵盖银行、保险、通信、互联网等多个领域 </span>
        <div></div>
      </div>
      <div class="brand_logo">
        <swiper ref="mySwiper" :options="onSlideChange1">
          <swiper-slide>
            <div style="width: 100%; height: 448px">
              <img
                :src="`${$link}/webimg/tab6.png`"
                style="width: 100%; height: 448px"
                alt=""
              />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div style="width: 100%; height: 448px">
              <img
                :src="`${$link}/webimg/tab3.png`"
                style="width: 100%; height: 448px"
                alt=""
              />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div style="width: 100%; height: 448px">
              <img
                :src="`${$link}/webimg/tab7.png`"
                style="width: 100%; height: 448px"
                alt=""
              />
            </div>
          </swiper-slide>
        </swiper>
        <span @click="toLink('/equityGoods')"> 查看更多合作品牌 > </span>
      </div>
    </div>
    <div class="contact_box" id="footForm">
      <div class="interaction_Box">
        <p style="color: #fff; letter-spacing: 1.67px">体验产品服务</p>
        <span style="color: #fff; letter-spacing: 1px">
          联系我们 400-658-0368
        </span>
        <div style="background-color: #fff"></div>
      </div>
      <div class="contact_form_box">
        <div class="contact_form_left">
          <div class="contact_info">
            <img :src="`${$link}/webimg/address_logo.png`" alt="" />
            <div>
              <span>联系地址</span>
              <p style="margin-bottom: 10px">
                深圳总部：深圳市福田区莲花街道康欣社区景田西路17号赛格景苑A、B栋A栋2008
              </p>
              <p>南宁分部：南宁市兴宁区堽路三里一巷45号实验综合楼</p>
            </div>
          </div>
          <div class="contact_info">
            <img :src="`${$link}/webimg/phone_logo.png`" alt="" />
            <div>
              <span>联系电话</span>
              <p>400-658-0368</p>
            </div>
          </div>
          <div class="contact_info">
            <img :src="`${$link}/webimg/time_logo.png`" alt="" />
            <div>
              <span>服务时间</span>
              <p>7*24小时在线——线上一对一咨询服务</p>
            </div>
          </div>
        </div>
        <div class="contact_form_right">
          <span class="form_title">联系我们</span>
          <el-form
            ref="fooForm"
            label-position="top"
            :model="form"
            :rules="rules"
            label-width="80px"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="您的名字" prop="userName">
                  <el-input
                    style="width: 200px; height: 32px"
                    v-model="form.userName"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="公司名称" prop="companyName">
                  <el-input v-model="form.companyName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="电话号码" prop="phoneNumber">
                  <el-input v-model.number="form.phoneNumber" />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="您想要了解的权益或营销场景"
                  prop="cooperationIntention"
                >
                  <el-input
                    type="textarea"
                    v-model="form.cooperationIntention"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-button type="primary" @click="footSubmit">提交</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 回到顶部 -->
    <toTop @bottomScroll="bottomScrollClick" />
  </div>
</template>

<script>
export default {
  name: 'webIndex',
  data () {
    return {
      // 银行简介
      bankTexts: [
        '通过赛迪文服务商在第三方支付宝平台支付满减，提高建设银行用户活跃度、资产动账',
        '通过赛迪文积分商城、积分营销，为农业银行提升客户资产规模',
        '通过赛迪文定制活动、积分运营、微信立减金发放，为中国银行提升在客户活跃、开卡等数据指标',
        '与交通银行进行微信绑卡相关合作，为银行提供微信绑卡优惠等相关活动，提升用户活跃度',
        '通过赛迪文权益商品供应，实现秒杀活动奖励发放，为招商银行提升用户活跃度',
        '通过赛迪文定制银行立减活动，促进开卡绑卡业务，提升了银行客户活跃度等数据',
        '与兴业银行进行微信支付满减活动，支持多种线下满减场景，并对达标的用户发放支付立减金',
        '与北部湾银行进行长期，提供各种合作权益类及活动技术支撑',
        '长期与中国邮政提供支付宝/微信立减金的合作，为银行解决了权益供货的问题',
        '与苏州银行签订长期权益供应合同，赛迪文为行方提供丰富的权益品类。',
        '赛迪文与四川银行通过微信立减金发放合作，为四川银行提升客户活跃度。',
        '通过赛迪文合作后，配置微信立减金，行方用户参与获得立减金。',
        '与桂林银行进行长期合作，支撑银行各项活动内容，提供各种权益商品',
        '与赛迪文定制大转盘活动，用户参与抽奖后获取积分。',
        '赛迪文与江南农村银行展开权益采购活动，为行方供货'
      ],
      equityIndex: 0,
      caseIndex: 0,
      onSlideChange: {
        slidesPerView: 3, // 显示个数
        spaceBetween: 20, // 每个之间的间距
        loop: true,
        // pagination: {
        //   // 分液器
        //   el: '.swiper-pagination',
        //   clickable: true
        // },
        navigation: {
          // 按钮分页
          prevEl: '.swiper-btn-left',
          nextEl: '.swiper-btn-right'
        }
      },
      onSlideChange1: {
        autoplay: true,
        loop: true
      },
      form: {
        userName: '',
        phoneNumber: null,
        cooperationIntention: '',
        companyName: ''
      },
      rules: {
        cooperationIntention: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入您想要了解的权益或营销场景'
          }
        ],
        phoneNumber: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入电话号码'
          },
          { type: 'number', message: '电话号码必须为数字值' }
        ],
        companyName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入公司名称'
          }
        ],
        userName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入您的名字'
          }
        ]
      }
    }
  },
  mounted () {
    let contactUs = this.$route.query.contact
    if (contactUs) {
      this.bottomScrollClick()
    }
  },
  methods: {
    // tabs切换
    equityClick (e) {
      this.equityIndex = e
    },
    caseTabsClick (e) {
      this.caseIndex = e
    },
    footSubmit () {
      this.$refs.fooForm.validate(valid => {
        if (valid) {
          console.log('tongguole')
          this.$api.intentionToCooperate(this.form).then(res => {
            if (res.code == 200) {
              this.$message({
                showClose: true,
                message: '提交成功！',
                type: 'success',
                offset: 100,
                customClass: 'success-tips'
              })
              this.form = {
                userName: '',
                phoneNumber: null,
                cooperationIntention: '',
                companyName: ''
              }
            }
            if (res.code == 500) {
              this.$message.error('提交失败！')
              this.form = {
                userName: '',
                phoneNumber: null,
                cooperationIntention: '',
                companyName: ''
              }
            }
          })
        }
      })
    },
    // 跳转更多案例
    toLink (e) {
      localStorage.setItem('current_page', e)
      this.$router.push({
        path: e
      })
    },
    // 设置滚动条
    bottomScrollClick () {
      this.$nextTick(() => {
        let scrollEl = this.$refs.mianscroll
        document.body.scrollTop = scrollEl.scrollHeight
        document.documentElement.scrollTop = scrollEl.scrollHeight
      })
    }
  }
}
</script>

<style>
.success-tips {
  height: 68px !important;
  font-size: 30px !important;
}
.success-tips .el-message__content {
  font-size: 20px !important;
}
.el-msg-class {
  padding: 100px 50px !important;
  font-size: 80px !important;
}
.el-msg-class > p {
  font-size: 34px !important;
}
</style>

<style lang="less" scoped>
::v-deep .el-notification {
  width: 700px;
  display: flex;
}
::v-deep .el-message {
  width: 500px;
  height: 200px;
  background-color: skyblue;
}
::v-deep .el-message--success {
  width: 500px;
  height: 200px;
  background-color: yellow;
}
* {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.container {
  width: 100%;
  margin: 0 auto;
  .el-message--success .el-message__content {
    font-size: 38px;
    font-weight: 700;
  }
  .banner {
    width: 1200px;
    height: 800px;
    margin: 0 auto;
    padding: 0;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;

    .banner_img {
      width: 717px;
      height: 66px;
      margin-top: 230px;
      .banner_text {
        font-weight: 500;
        font-size: 36px;
        color: #ffffff;
        letter-spacing: 5px;
        text-align: center;
        display: block;
        margin-top: 30px;
      }
      // background-color: skyblue;
    }
    .banner_form {
      width: 1200px;
      height: 123px;
      margin-top: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .el-button--primary {
        width: 280px;
        height: 60px;
      }

      ::v-deep .el-input__inner {
        width: 280px;
        height: 60px;
        background: #ffffff;
        border-radius: 5px;
        text-align: center;
        color: #777777;
        font-size: 16px;
        font-weight: 500;
        line-height: 0;
        letter-spacing: 1px;
        padding: 0;
      }
      // ::v-deep input::-webkit-input-placeholder {
      //   color: blue;
      // }
      // ::v-deep input::placeholder {
      //   color: red;
      // }

      p {
        text-align: left;
        font-weight: 600;
        font-size: 24px;
        color: #ffffff;
        letter-spacing: 1.33px;
      }
      .form {
        width: 1200px;
        text-align: left;
        // background-color: skyblue;
        display: flex;
        justify-content: space-between;
        .form_item {
          width: 280px;
          height: 60px;
        }
      }
    }
  }
  /* 悦动互动营销权益平台 */
  .interaction_Box {
    margin: 0;
    height: 260px;
    box-sizing: border-box;
    padding-top: 100px;
    position: relative;

    p {
      // height: 42px;
      font-weight: 700;
      font-size: 30px;
      color: #333333;
    }
    span {
      margin-top: 15px;
      display: inline-block;
      color: #00a0fb;
    }
    div {
      display: inline-block;
      width: 86px;
      height: 4px;
      position: absolute;
      top: 197px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #00a0fb;
      border-radius: 2px;
    }
  }
  .interaction_tabs {
    width: 1200px;
    margin: 0 auto;
    height: 443px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    text-align: left;
    .interaction_tabs_item {
      width: 386px;
      height: 443px;
      background: #ffffff;
      border: 1px solid #e1e8f0;
      border-radius: 10px;
      padding: 30px 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .interaction_tabs_item_top {
        height: 82px;
        border-bottom: 2px solid #e9eef4;
        display: flex;
        img {
          display: block;
          width: 60px;
          height: 60px;
        }
        span {
          padding-top: 0;
          display: block;
          line-height: 60px;
          margin-left: 10px;
          font-weight: 500;
          font-size: 21px;
          color: #777777;
          letter-spacing: 1.17px;
        }
      }
      .interaction_tabs_item_centre {
        flex: 1;
        p {
          width: 346px;
          height: 128px;
          font-family: PingFang-SC-Medium;
          font-weight: 500;
          font-size: 16px;
          color: #555555;
          letter-spacing: 0.89px;
          line-height: 32px;
          margin-top: 20px;
        }
      }
      .interaction_tabs_item_bom {
        width: 100%;
        height: 70px;
        span {
          display: inline-block;
          height: 30px;
          padding: 5px 10px;
          background: #f8fafc;
          border-radius: 5px;
          box-sizing: border-box;
          font-family: PingFang-SC-Medium;
          font-weight: 500;
          font-size: 14px;
          color: #999999;
          letter-spacing: 0.78px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
    .interaction_tabs_left {
      width: 76px;
      height: 100%;
      // background-color: #ccc;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .tabs_box_item {
        width: 120px;
        height: 120px;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .equity_active0 {
          color: #00a0fb;
          position: relative;

          // &::after {
          //   content: '';
          //   display: inline-block;
          //   border-bottom: 10px solid transparent;
          //   border-top: 10px solid transparent;
          //   border-right: 10px solid #f8fafc;
          //   position: absolute;
          //   top: -12px;
          //   right: -52px;
          // }
        }
        .equity_active1 {
          color: #f68e56;
          position: relative;
        }

        .equity_active2 {
          color: #0f62fe;
          position: relative;
        }
        span,
        img {
          display: block;
        }
        span {
          font-weight: 500;
          font-size: 18px;
          color: #555555;
          margin-top: 10px;
        }
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
    .active_qy_tabs0 {
      box-shadow: 0 0 15px 0 #0000001a;
      .interaction_tabs_item_top {
        border-bottom: 2px solid #00a0fb;
        span {
          color: #00a0fb;
        }
      }
      .interaction_tabs_item_bom {
        span {
          background: #ecf8ff;
          color: #00a0fb;
        }
      }
    }
    .active_qy_tabs1 {
      box-shadow: 0 0 15px 0 #0000001a;
      .interaction_tabs_item_top {
        border-bottom: 2px solid #f68e56;
        span {
          color: #f68e56;
        }
      }
      .interaction_tabs_item_bom {
        span {
          background: #fff6f1;
          color: #f68e56;
        }
      }
    }
    .active_qy_tabs2 {
      box-shadow: 0 0 15px 0 #0000001a;
      .interaction_tabs_item_top {
        border-bottom: 2px solid #1a69fe;
        span {
          color: #0f62fe;
        }
      }
      .interaction_tabs_item_bom {
        span {
          background: #f3f7ff;
          color: #0f62fe;
        }
      }
    }
    .interaction_tabs_right {
      width: 1074px;
      height: 100%;
      padding: 104px 80px 80px 80px;
      box-sizing: border-box;
      text-align: left;
      background: #f8fafc;
      border-radius: 10px;
      .interaction_tabs_right_top {
        margin-bottom: 52px;
        position: relative;
        span,
        p {
          display: block;
          font-weight: 500;
        }
        b {
          display: block;
          width: 60px;
          height: 4px;
          background: #00a0fb;
          border-radius: 2px;
          position: absolute;
          top: -24px;
          left: 0;
        }
        span {
          font-size: 18px;
          color: #333333;

          margin-bottom: 15px;
        }
        p {
          font-size: 16px;
          color: #555555;
          line-height: 32px;
        }
      }
      .interaction_tabs_right_bom {
        display: flex;
        justify-content: space-between;
        padding: 0 56px;
        .interaction_tabs_right_bom_item {
          display: flex;
          flex-direction: column;
          img {
            display: block;
            width: 70px;
            height: 56px;
            margin-bottom: 27px;
          }
          span {
            display: block;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
          }
        }
      }
    }
  }
  /* 服务保障 */
  .safeguard {
    width: 100%;
    height: 880px;
    background-color: skyblue;
    background-image: url('@{link}/webimg/swiper_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .swiper-container {
      width: 1200px;
      height: 526px;
      margin: 0 auto;
      // margin-top: 78px;
      // background-color: #ccc;
      position: relative;
    }
    .swiper-box {
      width: 1200px;
      margin: 0 auto;
      position: relative;
      .swiper-btn-left {
        width: 70px;
        height: 70px;
        background-image: url('@{link}/webimg/jiantou_left.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        left: -130px;
      }
      .swiper-btn-right {
        width: 70px;
        height: 70px;
        background-image: url('@{link}/webimg/jiantou_right.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        right: -130px;
      }
    }

    .swiper_item {
      width: 386px;
      height: 526px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .swiper_item_top {
        width: 386px;
        height: 78px;
        background-color: transparent;
        img {
          display: block;
          width: 156px;
          height: 156px;
          position: absolute;
          top: 0;
          z-index: 2;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .swiper_item_bom {
        background: #ffffff;
        width: 386px;
        height: 448px;
        border-radius: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          display: block;
          width: 60px;
          height: 4px;
          background: #00a0fb;
          border-radius: 2px;
          position: absolute;
          top: 108px;
          left: 50%;
          transform: translateX(-50%);
        }

        span {
          display: block;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          letter-spacing: 1px;
          text-align: center;
          margin-top: 142px;
          margin-bottom: 15px;
        }
        p {
          width: 306px;
          height: 140px;
          font-weight: 500;
          font-size: 16px;
          color: #555555;
          letter-spacing: 0.89px;
          text-align: center;
          line-height: 28px;
        }
        .swiper_item_bom_btn {
          width: 386px;
          height: 36px;
          margin-top: 30px;
          display: flex;
          justify-content: center;
          .swiper_item_bom_btn_let,
          .swiper_item_bom_btn_rig {
            height: 36px;
            border: 1px solid #00a0fb;
            border-radius: 5px;
            padding: 8px 12px;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 14px;
            color: #00a0fb;
            letter-spacing: 0.78px;
          }
          .swiper_item_bom_btn_let {
            margin-right: 20px;
          }
        }
      }
    }
  }
  /* 案例 */
  .case {
    width: 100%;
    height: 340px;
    background-image: url('@{link}/webimg/case_001.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 80px;
    .case_box {
      width: 1200px;
      margin: 0 auto;
      background-color: skyblue;
      position: relative;
      .case_item {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 930px;
        height: 360px;
        top: -80px;
        box-shadow: 0 0 15px 0 #0000001a;
        background: #ffffff;
        border-radius: 10px;

        .bank_item_bg1 {
          width: 210px;
          height: 220px;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: -1;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .bank_item_bg2 {
          width: 113px;
          height: 38px;
          position: absolute;
          left: 150px;
          bottom: 52px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          width: 694px;
          height: 72px;
          text-align: left;
          margin-left: 150px;
          font-weight: 500;
          font-size: 20px;
          color: #555555;
          letter-spacing: 1px;
          line-height: 36px;
          margin-top: 100px;
        }
        .case_comma {
          display: block;
          width: 45px;
          height: 45px;
          background-image: url('@{link}/webimg/douhao.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: absolute;

          top: 45px;
          left: 77px;
        }
        button {
          margin: 0;
          padding: 0;
          outline: none;
          width: 183px;
          height: 55px;
          border: 1px solid #00a0fb;
          border-radius: 10px;
          background: #ffffff;
          position: absolute;
          right: 87px;
          bottom: 44px;
          span {
            width: 133px;
            height: 25px;
            font-weight: 500;
            font-size: 18px;
            color: #00a0fb;
            letter-spacing: 0.9px;
          }
        }
      }
    }
  }
  .case_tabs {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 145px;
    position: relative;
    .case_tabs_item {
      width: 220px;
      height: 120px;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      img {
        width: 220px;
        height: 120px;
        display: block;
      }
    }
    .case_active {
      border: 1px solid #00a0fb;
    }
    .more {
      font-weight: 500;
      font-size: 18px;
      color: #00a0fb;
      position: absolute;
      top: 470px;
      left: 50%;
      transform: translateX(-50%);
      cursor: default;
    }
  }
  .brand_box {
    width: 100%;
    height: 889px;
    background-image: url('@{link}/webimg/bg-front2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .brand_logo {
      width: 1200px;
      height: 445px;
      margin: 0 auto;
      position: relative;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      span {
        position: absolute;
        bottom: -85px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        font-weight: 500;
        font-size: 18px;
        color: #00a0fb;
        cursor: default;
      }
    }
  }
  .contact_box {
    width: 100%;
    margin: 0;
    height: 789px;
    background: url('@{link}/webimg/form_bg1.png');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    .contact_form_box {
      width: 1200px;
      margin: 0 auto;
      height: 528px;
      display: flex;
      .contact_form_left {
        width: 720px;
        height: 428px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .contact_info {
          width: 700px;
          display: flex;
          img {
            display: block;
            width: 60px;
            height: 60px;
            margin-right: 15px;
          }
          div {
            display: flex;
            flex-direction: column;
            span {
              display: block;
              width: 355px;
              height: 25px;

              font-weight: 500;
              font-size: 18px;
              color: #ffffff;
              letter-spacing: 1px;
              margin-bottom: 13px;
            }
            p {
              width: 624px;
              height: 22px;
              font-weight: 500;
              font-size: 16px;
              color: #ffffff;
              letter-spacing: 0.89px;
              // margin-bottom: 10px;
            }
          }
        }
      }
      .contact_form_right {
        width: 480px;
        height: 428px;
        background: #ffffff;
        border-radius: 10px;
        padding: 30px;
        box-sizing: border-box;
        text-align: left;
        .form_title {
          display: inline-block;
          width: 68px;
          height: 22px;
          margin-left: 176px;
          margin-bottom: 20px;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          letter-spacing: 0.89px;
        }
        ::v-deep .el-textarea__inner {
          height: 80px;
          font-size: 14px;
        }
        ::v-deep .el-input__inner {
          height: 32px;
          line-height: 32px;
          font-size: 14px;
        }
        ::v-deep .el-form-item__label {
          font-weight: 500;
          font-size: 14px;
          color: #777777;
          letter-spacing: 0.78px;
          line-height: 20px;
        }
        ::v-deep .el-form-item__content {
          margin-bottom: 20px;
          line-height: 0;
        }
        ::v-deep .el-button--primary {
          width: 420px;
          height: 32px;
          background: #00a0fb;
          border-radius: 5px;
          padding: 0;

          span {
            display: inline-block;
            width: 30px;
            height: 20px;
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;
            text-align: center;
            letter-spacing: 0.78px;
          }
        }
      }
    }
  }
}
</style>
