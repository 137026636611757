<template>
  <div class="toast-box" v-if="showToast" @touchmove.prevent>
    <div class="toast" v-if="state && state != 'tips'">
      <i v-if="state == 'succeed'" class="el-icon-circle-check icon"></i>
      <i v-if="state == 'fail'" class="el-icon-circle-close icon"></i>
      <i v-if="state == 'loading'" class="el-icon-loading icon"></i>
      <div class="copy">{{msg}}</div>
    </div>
    <div v-else class="tips-copy">{{msg}}</div>
  </div>
</template>

<script>
  export default {
    name: "mobileToast",
    props: {
      msg: String,
      state: String
    },
    data() {
      return {
        showToast: false
      }
    },
    watch: {
      state: {
        handler(val) {
          if(val) {
            this.showToast = true
            setTimeout(() => {
              this.showToast = false
            }, 2500)
          } else {
            this.showToast = false
          }
        },
        immediate:true
      }
    }
  }
</script>

<style lang="less" scoped>
.toast-box{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .toast{
    color: #ffffff;
    background: rgba(0, 0, 0, 0.6);
    padding: 0.3rem 0.65rem 0.6rem;
    border-radius: 0.1rem;
    .icon{
      font-size: 0.65rem;
    }
    .copy{
      font-size: 0.24rem;
      margin-top: 0.1rem;
      letter-spacing: 1px;
    }
  }
  .tips-copy{
    color: #ffffff;
    background: rgba(0, 0, 0, 0.6);
    padding: 0.3rem 0.65rem;
    border-radius: 0.1rem;
    font-size: 0.24rem;
    letter-spacing: 1px;
  }
}
</style>