<template>
  <div>
    <div class="jiantou">
      <div id="top_div">
        <img
          :src="`${$link}/webimg/to_top1.png`"
          alt=""
        />
      </div>
      <div class="top_div_show">
        <a href="#header" style="text-decoration: none; color: #fff">
          <div class="top_div_bom">
            <img
              :src="`${$link}/webimg/to_top1.png`"
              alt=""
            />
            <span> 返回顶部 </span>
          </div>
        </a>
      </div>
    </div>

    <div class="max_box">
      <div id="top_box" class="lianxi" v-if="minBoxShow">
        <img
          :src="`${$link}/webimg/to_top2.png`"
          alt=""
        />
      </div>
      <div class="show_box">
        <div class="lianxi_box" id="box">
          <div class="top">
            <img
              :src="`${$link}/webimg/to_top2.png`"
              alt=""
            />
            <span>联系我们</span>
          </div>
          <a style="text-decoration: none; color: #fff">
            <div @click="toLink" class="centen">获取资料</div>
          </a>
          <div class="bom">400-658-0368</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'toTop',
  data () {
    return {
      maxBoxShow: false,
      minBoxShow: true
    }
  },
  mounted () {
    window.onscroll = function () {
      var t = document.documentElement.scrollTop || document.body.scrollTop
      var top_div = document.getElementById('top_div')
      var top_box = document.getElementById('top_box')
      // var lianxi_box = document.getElementById('lianxi_box')
      if (t >= 300) {
        top_box.style.opacity = 1
        top_div.style.opacity = 1
      } else {
        top_div.style.opacity = 0
        top_box.style.opacity = 0
      }
    }
  },
  methods: {
    // 鼠标移入
    mouseoverEvent () {
      this.maxBoxShow = true
      console.log('移入')
    },
    // 鼠标移除
    mouseoutEvent () {
      this.maxBoxShow = false
      console.log('移除')
    },
    toLink () {
      let path = localStorage.getItem('current_page')
      if (path == '/home') {
        this.$emit('bottomScroll')
      } else {
        localStorage.setItem('current_page', '/home')
        this.$router.push({ path: '/home', query: { contact: 1 } })
      }
    }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
}
#top_div {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 5px;
  position: fixed;
  right: 20px;
  bottom: 200px;
  display: flex;
  justify-content: center;
  background-color: #00a0fb;
  align-items: center;

  img {
    width: 26px;
    height: 26px;
  }
}
.jiantou:hover {
  .top_div_show {
    display: inline;
  }
}
.top_div_show {
  display: none;
}
.top_div_bom {
  width: 200px;
  height: 60px;
  background: #00a0fb;
  border-radius: 5px;
  position: fixed;
  z-index: 9999999999999;
  right: 20px;
  bottom: 200px;
  display: flex;
  align-items: center;
  img {
    display: block;
    width: 26px;
    height: 26px;
    margin: 0 10px 0 17px;
  }
  span {
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
    display: block;
  }
}
.max_box {
  &:hover {
    .show_box {
      display: inline;
    }
  }
}

.lianxi {
  cursor: default;
  position: fixed;
  right: 20px;
  bottom: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background: #00a0fb;
  z-index: 10000px;
  img {
    width: 26px;
    height: 26px;
  }
}
.show_box {
  display: none;
}
.lianxi_box {
  width: 200px;
  height: 175px;
  background: #00a0fb;
  border-radius: 5px;
  position: fixed;
  z-index: 9999999999999;
  right: 20px;
  bottom: 270px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 15px 15px;
  box-sizing: border-box;
  .top {
    display: flex;
    align-items: center;
    width: 170px;
    padding: 0 2px;
    cursor: default;
    img {
      // display: block;
      margin-left: 2px;
      margin-right: 10px;
    }
  }
  .centen,
  .bom {
    width: 170px;
    height: 40px;
    line-height: 40px;
    background: #ffffff1a;
    border: 1px solid #ffffff;
    border-radius: 5px;
    text-align: center;
    cursor: default;
  }
  .centen {
    margin: 10px 0;
  }
}
</style>
