<template>
  <div class="advantage-box">
    <div class="advantage">
      <div class="line">
        <div class="opt">
          <div class="name">权益供给</div>
          <div class="tips">1000+数字商品兑换码</div>
        </div>
        <div class="opt">
          <div class="name">爆款营销</div>
          <div class="tips">全场景工具赋能</div>
        </div>
      </div>
      <div class="line">
        <div class="opt">
          <div class="name">技术研发</div>
          <div class="tips">定制化开发</div>
        </div>
        <div class="opt">
          <div class="name">支持多端接入</div>
          <div class="tips">API/H5/小程序</div>
        </div>
        <div class="opt">
          <div class="name">运营服务</div>
          <div class="tips">行业专家1V1</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "mobileAdvantage"
  }
</script>

<style lang="less" scoped>
.advantage-box{
  margin: 0 0.29rem;
  .advantage{
    color: #fff;
    .line{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.14rem;
      .opt{
        flex: 1;
        border: 1px solid #fff;
        border-radius: 0.1rem;
        margin: 0 0.07rem;
        overflow: hidden;
        .name{
          font-size: 0.28rem;
          line-height: 0.6rem;
          background: #2A74FF;
          letter-spacing: 1px;
        }
        .tips{
          font-size: 0.24rem;
          line-height: 0.7rem;
          background: #5892FF;
          letter-spacing: 1px;
        }
      }
    }
  }
}
</style>