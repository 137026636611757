<template>
  <div class="mobile-title-two">
    <div class="two-title">{{title}}</div>
    <div class="two-info">{{message}}</div>
    <div class="two-line"></div>
  </div>
</template>

<script>
  export default {
    name: "mobileTitleTwo",
    props: {
      title: {
        type: String,
        default: ''
      },
      message: {
        type: String,
        default: ''
      }
    },
  }
</script>

<style lang="less" scoped>
.mobile-title-two{
  margin-top: 0.8rem;
  .two-title{
    color: #333333;
    font-size: 0.32rem;
    letter-spacing: 1.5px;
    line-height: 0.44rem;
  }
  .two-info{
    font-size: 0.24rem;
    color: #0F62FE;
    letter-spacing: 1.34px;
    margin-top: 0.2rem;
    line-height: 0.34rem;
  }
  .two-line{
    height: 0.04rem;
    background: #0F62FE;
    border-radius: 0.04rem;
    margin: 0.2rem auto 0;
    width: 0.8rem;
  }
}
</style>