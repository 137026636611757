import { request } from './request.js'

const api = {
	// intentionToCooperate: params => request('get','/openapi/officialWebsite/insertCooperationIntention', params, 'params', false),
	// 新增合作意向接口
	intentionToCooperate: params => request('post','/openapi/officialWebsite/insertCooperationIntention', params, 'data', false),

	// 查询案例合作列表接口
	listCaseCooperation: params => request('post','/openapi/officialWebsite/listCaseCooperation', params, 'data', false),
	// 获取验证码
	// getCode: params => request('post','/awardToolAuth/getCode', params, 'data', false),
	// 登录接口
	
}
export default api