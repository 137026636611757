<template>
  <div class="container">
    <div class="fool">
      <p>Copyright©2009-2022 深圳赛迪文信息科技有限公司</p>
      <span>
        ICP备案号：<a
          style="color: #ffffff; text-decoration: none"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          >粤ICP备2022135991号</a
        >
        ｜ 电信增值许可证：粤B2-20230695
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'webHead',
  data () {
    return {
      key: 'value'
    }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  font-size: 14px;
}
.container {
  width: 100%;
  height: 130px;
  background: #41434c;
}
.fool {
  display: flex;
  flex-direction: column;
  p {
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    display: inline-block;
    color: #ffffff;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  span {
    display: inline-block;
    height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
  }
}
</style>
